import { NgModule } from '@angular/core';
import { JagaCheckoutProgressModule } from './jaga-checkout-progress/jaga-checkout-progress.module';
import {
  CheckoutLoginModule,
  CheckoutOrchestratorModule,
  CheckoutPaymentMethodModule,
  CheckoutProgressMobileBottomModule,
  CheckoutProgressMobileTopModule,
} from '@spartacus/checkout/base/components';
import { JagaPlaceOrderModule } from './jaga-place-order/jaga-place-order.module';
import { JagaCheckoutOrderSummaryModule } from './jaga-checkout-order-summary/jaga-checkout-order-summary.module';
import { JagaCheckoutDeliveryModeModule } from './jaga-delivery-mode/jaga-delivery-mode.module';

@NgModule({
  declarations: [],
  imports: [
    CheckoutOrchestratorModule,
    JagaCheckoutOrderSummaryModule,
    JagaCheckoutProgressModule,
    CheckoutProgressMobileTopModule,
    CheckoutProgressMobileBottomModule,
    JagaCheckoutDeliveryModeModule,
    CheckoutPaymentMethodModule,
    JagaPlaceOrderModule,
    CheckoutLoginModule,
  ],
})
export class JagaCheckoutComponentsModule {}
