import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { JagaOrderConfirmationItemsComponent } from './jaga-order-confirmation-items-component';
import { JagaCartItemListComponent } from '@jaga/cart';

@NgModule({
  declarations: [JagaOrderConfirmationItemsComponent],
  imports: [CommonModule, I18nModule, JagaCartItemListComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderConfirmationItemsComponent: {
          component: JagaOrderConfirmationItemsComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    }),
  ],
})
export class JagaOrderConfirmationItemsComponentModule {}
