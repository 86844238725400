<div class="cx-section">
  <ng-container
    *ngIf="
      (customerAddresses$ | async).length &&
      !(showAddAddressForm || showEditAddressForm)
    "
  >
    <div
      *ngIf="!(addressesStateLoading$ | async); else loading"
      class="row cx-address-deck"
    >
      <div
        *ngFor="let address of customerAddresses$ | async"
        class="col-md-6 cx-address-card"
      >
        <cx-card
          (cancelCard)="cancelCard()"
          [border]="true"
          [content]="getCardContent(address) | async"
          [editMode]="address.id === editCard"
          [fitToContainer]="true"
        ></cx-card>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="col-md-12 cx-address-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
