import { Observable } from 'rxjs';
import { JagaCart } from '../../../../../jaga-cart/base/core/models/jaga-cart.model';

export abstract class DeliveryDateAdapter {
  /**
   * Abstract method used to set remarks on cart
   */
  abstract setDeliveryDate(
    userId: string,
    cartId: string,
    deliveryDate: string,
  ): Observable<JagaCart>;
}
