import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeliveryDateAdapter } from './delivery-date.adapter';
import { JagaCart } from '../../../../../jaga-cart/base/core/models/jaga-cart.model';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDateConnector {
  constructor(protected adapter: DeliveryDateAdapter) {}

  setDeliveryDate(
    userId: string,
    cartId: string,
    remarks: string,
  ): Observable<JagaCart> {
    return this.adapter.setDeliveryDate(userId, cartId, remarks);
  }
}
