import { NgModule } from '@angular/core';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import {
  CHECKOUT_FEATURE,
  CheckoutRootModule,
} from '@spartacus/checkout/base/root';
import {
  checkoutTranslationChunksConfig,
  checkoutTranslations,
} from '@spartacus/checkout/base/assets';
import {
  checkoutScheduledReplenishmentTranslationChunksConfig,
  checkoutScheduledReplenishmentTranslations,
} from '@spartacus/checkout/scheduled-replenishment/assets';
import { CheckoutScheduledReplenishmentRootModule } from '@spartacus/checkout/scheduled-replenishment/root';
import { JagaCheckoutB2BModule } from '../../../jaga-checkout/b2b/jaga-checkout-b2b.module';
import { CheckoutB2BRootModule } from '@spartacus/checkout/b2b/root';
import { JagaCheckoutModule } from '../../../jaga-checkout/base/jaga-checkout.module';
import { jagaB2bOccConfig } from '../../../jaga-checkout/b2b/occ/config/jaga-b2b-occ.config';

@NgModule({
  imports: [
    CheckoutRootModule,
    CheckoutB2BRootModule,
    CheckoutScheduledReplenishmentRootModule,
    JagaCheckoutModule,
    JagaCheckoutB2BModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () =>
            import('./checkout-wrapper.module').then(
              (m) => m.CheckoutWrapperModule
            ),
        },
      },
    }),
    provideConfig(jagaB2bOccConfig),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: checkoutTranslations,
        chunks: checkoutTranslationChunksConfig,
      },
    }),
    // provideConfig(<I18nConfig>{
    //   i18n: {
    //     resources: checkoutB2BTranslations,
    //     chunks: checkoutB2BTranslationChunksConfig,
    //   },
    // }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: checkoutScheduledReplenishmentTranslations,
        chunks: checkoutScheduledReplenishmentTranslationChunksConfig,
      },
    }),
  ],
})
export class CheckoutFeatureModule {}
