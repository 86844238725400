import {
  quickOrderAdapter,
  QuickOrderEntriesState,
  QuickOrderState,
  StateWithQuickOrder,
} from '../quick-order-state';
import { getQuickOrderState } from './feature.selector';
import { createSelector, MemoizedSelector } from '@ngrx/store';

export const getQuickOrderEntriesState: MemoizedSelector<
  StateWithQuickOrder,
  QuickOrderEntriesState
> = createSelector(
  getQuickOrderState,
  (state: QuickOrderState) => state.entries
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  quickOrderAdapter.getSelectors(getQuickOrderEntriesState);
