import { Component, ElementRef } from '@angular/core';
import { AddedToCartDialogComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { LaunchDialogService } from '@spartacus/storefront';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'jaga-added-to-cart-dialog',
  templateUrl: './jaga-added-to-cart-dialog.component.html',
})
export class JagaAddedToCartDialogComponent extends AddedToCartDialogComponent {
  constructor(
    activeCartFacade: ActiveCartFacade,
    launchDialogService: LaunchDialogService,
    routingService: RoutingService,
    el: ElementRef
  ) {
    super(activeCartFacade, launchDialogService, routingService, el);
  }
}
