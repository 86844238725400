import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
} from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { OrderFacade, OrderHistoryFacade } from '@spartacus/order/root';
import {
  catchError,
  map,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MollieOrderConfirmationGuard {
  constructor(
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected orderFacade: OrderFacade,
    protected orderHistoryFacade: OrderHistoryFacade,
  ) {}

  /**
   * Get the order details based on the :orderCode parameter from the URL.
   * If the order exists, we know the payment was successful and will redirect to the order confirmation page.
   * If the order does not exist, we will redirect back to the checkout and display a generic error message
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const orderCode = next.params.orderCode;
    this.orderHistoryFacade.loadOrderDetails(orderCode);

    return combineLatest([
      this.orderHistoryFacade.getOrderHistoryList(1),
      this.orderFacade.getOrderDetails(),
    ]).pipe(
      skipWhile(([list, order]) => list.orders.length < 1),
      map(([, order]) => {
        if (order && Object.keys(order).length < 1) {
          throw new Error('Order creation failed');
        }

        // this.orderFacade.confirmPlaceOrder(order);
      }),
      switchMap(() =>
        this.orderHistoryFacade.getOrderDetails().pipe(
          take(1),
          tap(() => this.routingService.go({ cxRoute: 'orderConfirmation' })),
          map(() => true),
        ),
      ),
      catchError((error) => {
        console.warn(error);
        this.routingService.go({ cxRoute: 'checkoutReviewOrder' });
        this.globalMessageService.add(
          { key: 'payment.errors.failed' },
          GlobalMessageType.MSG_TYPE_ERROR,
        );
        return of(false);
      }),
    );
  }
}
