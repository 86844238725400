<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="cart.entries">
    <jaga-order-summary
      [cart]="cart"
      [showAppliedCoupons]="false"
    ></jaga-order-summary>
    <div class="button-wrapper">
      <button (click)="backClicked()" class="btn btn-secondary" type="button">
        {{ "cartDetails.continueShopping" | cxTranslate }}
      </button>
      <button
        *ngIf="cart.entries.length"
        [routerLink]="{ cxRoute: 'checkout' } | cxUrl"
        class="btn btn-primary"
        type="button"
      >
        {{ "cartDetails.proceedToCheckout" | cxTranslate }}
      </button>
    </div>
  </ng-container>
</ng-container>
