import { Injectable, signal } from '@angular/core';
import {
  Command,
  CommandService,
  CommandStrategy,
  EventService,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
} from '@spartacus/core';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { JagaActiveCartService, JagaCart } from '@jaga/cart';
import { combineLatest, Observable } from 'rxjs';
import { JagaRemarksConnector } from '../connectors';
import { CheckoutRemarksSetEvent } from '@jaga/checkout';
import { OrderPlacedEvent } from '@spartacus/order/root';

@Injectable({
  providedIn: 'root',
})
export class JagaRemarksService {
  #remarks = signal(undefined);
  $placedOrder = this.eventService.get(OrderPlacedEvent);

  protected setRemarksCommand: Command<{ remarks: string }, JagaCart> =
    this.command.create(
      ({ remarks }) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.remarksConnector.setRemarks(userId, cartId, remarks).pipe(
              tap(() =>
                this.eventService.dispatch(
                  {
                    userId,
                    cartId,
                    remarks,
                  },
                  CheckoutRemarksSetEvent,
                ),
              ),
              tap(() => this.#remarks.set(remarks)),
            ),
          ),
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      },
    );

  constructor(
    protected activeCartService: JagaActiveCartService,
    protected userIdService: UserIdService,
    protected command: CommandService,
    protected remarksConnector: JagaRemarksConnector,
    protected eventService: EventService,
  ) {}

  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartService.takeActiveCartId(),
      this.activeCartService.isGuestCart(),
    ]).pipe(
      take(1),
      map(([userId, cartId, isGuestCart]) => {
        if (
          !userId ||
          !cartId ||
          (userId === OCC_USER_ID_ANONYMOUS && !isGuestCart)
        ) {
          throw new Error('Checkout conditions not met');
        }
        return [userId, cartId];
      }),
    );
  }

  getRemarks(): string {
    return this.#remarks();
  }

  setRemarks(remarks: string) {
    this.$placedOrder
      .pipe(
        skipWhile((order) => !order),
        tap(() => this.clearRemarks()),
      )
      .subscribe();

    return this.setRemarksCommand.execute({
      remarks,
    });
  }

  setRemarksSignal(remarks: string) {
    this.#remarks.set(remarks);
  }

  clearRemarks() {
    this.#remarks.set(undefined);
  }
}
