import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { JagaAddedToCartDialogComponent } from './jaga-added-to-cart-dialog.component';
import { JagaAddedToCartDialogEventListener } from './jaga-added-to-cart-dialog-event.listener';
import {
  IconModule,
  KeyboardFocusModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { jagaAddedToCartLayoutConfig } from './jaga-added-to-cart-layout.config';
import { JagaCartItemComponent } from '@jaga/cart';
import { JagaAddedToCartDialogCarouselComponent } from '../jaga-added-to-cart-dialog-carousel/jaga-added-to-cart-dialog-carousel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    I18nModule,
    IconModule,
    KeyboardFocusModule,
    JagaAddedToCartDialogCarouselComponent,
    UrlModule,
    SpinnerModule,
    JagaCartItemComponent,
  ],
  declarations: [JagaAddedToCartDialogComponent],
  exports: [JagaAddedToCartDialogComponent],
  providers: [provideDefaultConfig(jagaAddedToCartLayoutConfig)],
})
export class JagaAddedToCartDialogModule {
  constructor(
    _addToCartDialogEventListener: JagaAddedToCartDialogEventListener
  ) {
    // Intentional empty constructor
  }
}
