import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  CheckoutProgressComponent,
  CheckoutStepService,
} from '@spartacus/checkout/base/components';

@Component({
  selector: 'jaga-checkout-progress',
  templateUrl: './jaga-checkout-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaCheckoutProgressComponent extends CheckoutProgressComponent {
  constructor(protected checkoutStepService: CheckoutStepService) {
    super(checkoutStepService);
  }
}
