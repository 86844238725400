import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { QuickOrderEntry } from '../models/quick-order.model';
import { Suggestion } from '@spartacus/core';

export const QUICK_ORDER_FEATURE = 'JagaQuickOrder';

export const quickOrderAdapter = createEntityAdapter<QuickOrderEntry>({
  selectId: (entry) => entry.product.code,
});

export interface StateWithQuickOrder {
  [QUICK_ORDER_FEATURE]: QuickOrderState;
}

export interface QuickOrderState {
  entries: QuickOrderEntriesState;
  search: QuickOrderSearchState;
}

export interface QuickOrderEntriesState extends EntityState<QuickOrderEntry> {}

export interface QuickOrderSearchState {
  suggestions: Suggestion[];
}
