import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { JagaCartDetailsComponent } from './jaga-cart-details.component';
import { PromotionsModule } from '@spartacus/storefront';
import { JagaCartItemListComponent } from '../../../jaga-cart-shared/jaga-cart-item-list/jaga-cart-item-list.component';

@NgModule({
  declarations: [JagaCartDetailsComponent],
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    JagaCartItemListComponent,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CartComponent: {
          component: JagaCartDetailsComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class JagaCartDetailsModule {}
