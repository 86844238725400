import { CardModule, SpinnerModule } from '@spartacus/storefront';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { JagaShippingAddressComponent } from './jaga-shipping-address.component';
import { JagaAddressFormModule } from './jaga-address-form/jaga-address-form/jaga-address-form.module';
import { JagaAddressCardModule } from './jaga-address-card/jaga-address-card.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';

@NgModule({
  declarations: [JagaShippingAddressComponent],
  imports: [
    CommonModule,
    RouterModule,
    JagaAddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    JagaAddressCardModule,
  ],
  exports: [JagaShippingAddressComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutShippingAddress: {
          component: JagaShippingAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class JagaShippingAddressModule {}
