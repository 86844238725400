import { Component } from '@angular/core';
import { OrderConfirmationItemsComponent } from '@spartacus/order/components';
import { OrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'jaga-order-confirmation-items-component',
  templateUrl: './jaga-order-confirmation-items-component.html',
})
export class JagaOrderConfirmationItemsComponent extends OrderConfirmationItemsComponent {
  constructor(orderFacade: OrderFacade) {
    super(orderFacade);
  }
}
