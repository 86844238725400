import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  GlobalMessageComponentModule,
  KeyboardFocusModule,
  OutletModule,
  PageLayoutModule,
  PageSlotModule,
  SkipLinkModule,
} from '@spartacus/storefront';
import { AppComponent } from './app.component';
import { JagaModule } from './config/jaga.module';
import { SpartacusModule } from './spartacus/spartacus.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FeaturesConfig, OccConfig, provideConfig } from '@spartacus/core';
import { JagaOccConfig } from './jaga-checkout/base/occ/config/jaga-occ-config';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { JagaGtmModule } from './jaga-gtm/jaga-gtm.module';

registerLocaleData(localeNl);
registerLocaleData(localeFr);

const devImports = [];
if (!environment.production) {
  devImports.push(
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      features: {
        pause: false,
        lock: true,
        persist: true,
      },
      connectInZone: true,
    })
  );
}

const occConfig: JagaOccConfig = {
  backend: {
    occ: {
      endpoints: {
        baseSites:
          'basesites?fields=baseSites(uid,gtm,enableDatePickerCheckout,defaultLanguage(isocode),urlEncodingAttributes,urlPatterns,stores(currencies(isocode),defaultCurrency(isocode),languages(isocode),defaultLanguage(isocode)))',
      },
    },
  },
};

// only provide the `occ.baseUrl` key if it is explicitly configured, otherwise the value of
// <meta name="occ-backend-base-url" > is ignored.
// This in turn breaks the call to the API aspect in public cloud environments
if (environment.occBaseUrl) {
  occConfig.backend.occ.baseUrl = environment.occBaseUrl;
}
if (environment.prefix) {
  occConfig.backend.occ.prefix = environment.prefix;
} else {
  occConfig.backend.occ.prefix = '/occ/v2/';
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    KeyboardFocusModule,
    PageLayoutModule,
    PageSlotModule,
    GlobalMessageComponentModule,
    RouterModule,
    SkipLinkModule,
    OutletModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ...devImports,
    SpartacusModule,
    // TODO: PUT ME BACK TO ENABLE GTM
    // JagaGtmModule,
    JagaModule,
  ],
  providers: [
    provideConfig(occConfig as OccConfig),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '*',
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
