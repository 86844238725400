<label [class.dirty]="!!searchInput.value" class="searchbox">
  <input
    #searchInput
    class="form-control"
    (focus)="open()"
    (blur)="close($event)"
    (input)="search(searchInput.value)"
    (keydown.arrowdown)="focusNextChild($event)"
    (keydown.arrowup)="focusPreviousChild($event)"
    (keydown.enter)="addEntry($event, searchInput.value)"
    (keydown.escape)="close($event)"
    [disabled]="disabled"
    [placeholder]="'quickOrder.search.placeholder' | cxTranslate"
    aria-label="search"
  />

  <cx-icon
    (click)="addEntry($event, searchInput.value)"
    (keydown.enter)="addEntry($event, searchInput.value)"
    [type]="IconType.SEARCH"
    aria-label="search"
    class="search"
    tabindex="0"
  >
  </cx-icon>
</label>

<ng-container *ngIf="results$ | async as results">
  <div (click)="close($event)" [class.d-none]="!showResults" class="results">
    <div class="suggestions">
      <a
        (blur)="close($event)"
        (click)="addEntry($event, result)"
        (keydown.arrowdown)="focusNextChild($event)"
        (keydown.arrowup)="focusPreviousChild($event)"
        (keydown.enter)="addEntry($event, result)"
        (keydown.escape)="close($event, true)"
        *ngFor="let result of results"
        [innerHTML]="result"
        href="#"
      >
      </a>
    </div>
  </div>
</ng-container>
