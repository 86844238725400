import { JagaAccNavUIComponent } from './components/jaja-acc-nav-ui/jaga-acc-nav-ui.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaRegisterCustomerComponent } from './components/jaga-register-customer/jaga-register-customer.component';
import { I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import {
  FormErrorsModule,
  GenericLinkModule,
  IconConfig,
  IconModule,
  IconResourceType,
  NavigationModule,
  PageSlotModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { JagaLoginFormComponent } from './components/jaga-login-form/jaga-login-form.component';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import { JagaResetPasswordStoreModule } from './store/jaga-reset-password-store.module';
import { JagaLoginComponent } from './components/jaga-login/jaga-login.component';
import { RegisterComponentModule } from '@spartacus/user/profile/components';
import { JagaAccNavComponent } from './components/jaga-acc-nav/jaga-acc-nav.component';

@NgModule({
  declarations: [
    JagaRegisterCustomerComponent,
    JagaLoginFormComponent,
    JagaLoginComponent,
    JagaAccNavComponent,
    JagaAccNavUIComponent,
  ],
  imports: [
    CommonModule,
    RegisterComponentModule,
    ReactiveFormsModule,
    I18nModule,
    FormErrorsModule,
    RouterModule,
    UrlModule,
    SpinnerModule,
    PageSlotModule,
    JagaCommonModule,
    JagaResetPasswordStoreModule,
    IconModule,
    NavigationModule,
    GenericLinkModule,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        RegisterCustomerComponent: {
          component: JagaRegisterCustomerComponent,
        },
        ReturningCustomerLoginComponent: {
          component: JagaLoginFormComponent,
        },
        LoginComponent: {
          component: JagaLoginComponent,
        },
        NavigationComponent: {
          component: JagaAccNavComponent,
        },
      },
    }),
    provideConfig(<IconConfig>{
      icon: {
        symbols: {
          USER: 'fas fa-user',
          BRIEFCASE: 'fas fa-briefcase',
        },
        resources: [
          {
            type: IconResourceType.LINK,
            url: 'https://use.fontawesome.com/releases/v5.8.1/css/all.css',
          },
        ],
      },
    }),
  ],
})
export class JagaUserModule {}
