<ng-container *ngIf="order$ | async as order">
  <div class="cx-account-summary">
    <div class="row">
      <div
        *ngIf="order.deliveryAddress"
        class="cx-summary-card col-12 col-md-3"
      >
        <cx-card
          [content]="getAddressCardContent(order?.deliveryAddress) | async"
        ></cx-card>
      </div>
      <div
        *ngIf="order?.billingAddress"
        class="cx-summary-card col-12 col-md-3"
      >
        <cx-card
          [content]="
            getBillingAddressCardContent(order?.billingAddress) | async
          "
        ></cx-card>
      </div>
      <div *ngIf="order?.paymentType" class="cx-summary-card col-12 col-md-3">
        <cx-card
          [content]="getShippingMethodCardContent(order?.deliveryMode) | async"
        ></cx-card>
      </div>
      <div *ngIf="order.deliveryMode" class="cx-summary-card col-12 col-md-3">
        <cx-card
          [content]="getPaymentTypeCardContent(order?.paymentType) | async"
        ></cx-card>
      </div>
    </div>
  </div>
</ng-container>
