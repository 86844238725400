<div
  class="cx-modal-container"
  [cxFocus]="focusConfig"
  (esc)="dismissModal('Escape pressed')"
>
  <div class="cx-modal-content">
    <!-- Modal Header -->
    <ng-container *ngIf="loaded$ | async; else loading">
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{
            (addedEntryWasMerged$ | async)
              ? ("addToCart.itemsIncrementedInYourCart" | cxTranslate)
              : ("addToCart.itemsAddedToYourCart" | cxTranslate)
          }}
        </div>
        <button
          (click)="dismissModal('Cross click')"
          aria-label="Close"
          class="close"
          type="button"
        >
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div *ngIf="entry$ | async as entry" class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          <div class="cx-dialog-item col-sm-12 col-md-7">
            <jaga-cart-item
              (view)="dismissModal('Product selected')"
              [compact]="true"
              [item]="entry"
              [promotionLocation]="promotionLocation"
              [quantityControl]="getQuantityControl() | async"
            ></jaga-cart-item>
          </div>
          <!-- Separator -->
          <div
            class="cx-dialog-separator col-sm-12 d-xs-block d-sm-block d-md-none"
          ></div>
          <!-- Total container -->
          <div class="cx-dialog-actions col-sm-12 col-md-5">
            <div *ngIf="cart$ | async as cart" class="cx-dialog-total">
              <div>
                {{
                  "cartItems.cartTotal"
                    | cxTranslate : { count: cart.deliveryItemsQuantity }
                }}
              </div>

              <div>{{ cart.subTotal?.formattedValue }}</div>
            </div>

            <!-- Promotions -->
            <!--          <div-->
            <!--            *ngIf="orderPromotions$ | async as orderPromotions"-->
            <!--            class="cx-dialog-promotions">-->
            <!--            <cx-promotions [promotions]="orderPromotions"></cx-promotions>-->
            <!--          </div>-->

            <!-- Actions -->
            <div class="cx-dialog-buttons">
              <a
                (click)="!form.dirty && dismissModal('View Cart click')"
                [class.disabled]="form.dirty"
                [routerLink]="{ cxRoute: 'cart' } | cxUrl"
                autofocus
                class="btn btn-primary"
              >
                {{ "addToCart.viewCart" | cxTranslate }}
              </a>

              <a
                (click)="!form.dirty && dismissModal('Continue Shopping click')"
                [class.disabled]="form.dirty"
                class="btn btn-secondary"
              >
                {{ "addToCart.continueShopping" | cxTranslate }}
              </a>
            </div>
          </div>
        </div>

        <div class="cx-dialog-row">
          <jaga-added-to-cart-dialog-carousel
            [product]="entry.product"
            class="cx-dialog-item"
          >
          </jaga-added-to-cart-dialog-carousel>
        </div>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="cx-dialog-header modal-header">
        <div class="cx-dialog-title modal-title">
          {{ "addToCart.updatingCart" | cxTranslate }}
        </div>
        <button
          (click)="dismissModal('Cross click')"
          aria-label="Close"
          class="close"
          type="button"
        >
          <span aria-hidden="true">
            <cx-icon [type]="iconTypes.CLOSE"></cx-icon>
          </span>
        </button>
      </div>
      <!-- Modal Body -->
      <div class="cx-dialog-body modal-body">
        <div class="cx-dialog-row">
          <div class="col-sm-12">
            <cx-spinner></cx-spinner>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
