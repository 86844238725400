import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { JagaPlaceOrderComponent } from './jaga-place-order.component';
import { jagaPlaceOrderSpinnerLayoutConfig } from './jaga-place-order-spinner-layout.config';
import { FormErrorsModule } from '@spartacus/storefront';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';

@NgModule({
  declarations: [JagaPlaceOrderComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ReactiveFormsModule,
    FormErrorsModule,
  ],
  providers: [
    provideConfig(jagaPlaceOrderSpinnerLayoutConfig),
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPlaceOrder: {
          component: JagaPlaceOrderComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class JagaPlaceOrderModule {}
