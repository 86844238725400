import { ChangeDetectorRef, Component } from '@angular/core';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { I18nModule, UserIdService } from '@spartacus/core';
import {
  ActiveCartFacade,
  MultiCartFacade,
  SelectiveCartFacade,
} from '@spartacus/cart/base/root';
import { CommonModule } from '@angular/common';
import { JagaCartItemComponent } from '../jaga-cart-item/jaga-cart-item.component';

@Component({
  selector: 'jaga-cart-item-list',
  templateUrl: './jaga-cart-item-list.component.html',
  standalone: true,
  imports: [CommonModule, JagaCartItemComponent, I18nModule],
})
export class JagaCartItemListComponent extends CartItemListComponent {
  constructor(
    protected activeCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartFacade,
    protected userIdService: UserIdService,
    protected multiCartService: MultiCartFacade,
    protected cd: ChangeDetectorRef
  ) {
    super(
      activeCartService,
      selectiveCartService,
      userIdService,
      multiCartService,
      cd
    );
  }
}
