import { Component } from '@angular/core';
import {
  OrderDetailItemsComponent,
  OrderDetailsService,
} from '@spartacus/order/components';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsOrderDetailItemsComponent } from '@spartacus/core';

@Component({
  selector: 'jaga-order-detail-items',
  templateUrl: './jaga-order-detail-items.component.html',
})
export class JagaOrderDetailItemsComponent extends OrderDetailItemsComponent {
  constructor(
    orderDetailsService: OrderDetailsService,
    component: CmsComponentData<CmsOrderDetailItemsComponent>
  ) {
    super(orderDetailsService, component);
  }
}
