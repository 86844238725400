<ng-container *ngIf="items$ | async as items">
  <cx-carousel
    [items]="items"
    [template]="carouselItem"
    [title]="'crossSell.othersAlsoOrdered' | cxTranslate"
  >
  </cx-carousel>

  <ng-template #carouselItem let-item="item">
    <div class="mx-2 flex-grow-1 d-flex flex-column">
      <a
        [routerLink]="{ cxRoute: 'product', params: item } | cxUrl"
        class="flex-grow-1 justify-content-start"
        tabindex="0"
      >
        <cx-media [container]="item.images?.PRIMARY"></cx-media>
        <p class="name m-1">{{ item.name }}</p>
        <div class="price mb-1 mt-auto">
          {{ item.price?.formattedValue }}
        </div>
      </a>

      <jaga-add-to-cart
        *ngIf="item.stock?.stockLevelStatus !== 'outOfStock'"
        [confirmation]="'NONE'"
        [product]="item"
        class="d-block mx-auto mt-2 mb-4"
      >
      </jaga-add-to-cart>
    </div>
  </ng-template>
</ng-container>
