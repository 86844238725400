import { Component } from '@angular/core';
import {
  Address,
  CmsOrderDetailOverviewComponent,
  TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { Card } from '@spartacus/storefront/shared/components/card/card.component';
import { filter, map } from 'rxjs/operators';
import {
  OrderDetailsService,
  OrderOverviewComponent,
} from '@spartacus/order/components';
import { DeliveryMode, PaymentType } from '@spartacus/cart/base/root';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'jaga-order-confirmation-overview',
  templateUrl: './jaga-order-confirmation-overview.component.html',
})
export class JagaOrderConfirmationOverviewComponent extends OrderOverviewComponent {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    private translationService: TranslationService,
    protected component: CmsComponentData<CmsOrderDetailOverviewComponent>,
  ) {
    super(translationService, orderDetailsService, component);
  }

  getPaymentTypeCardContent(paymentType: PaymentType): Observable<Card> {
    return this.translationService.translate('paymentTypes.title').pipe(
      filter(() => Boolean(paymentType)),
      map((textTitle) => ({
        title: textTitle,
        textBold: paymentType.displayName,
      })),
    );
  }

  getAddressCardContent(deliveryAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      map((title) => {
        return this.getFormattedAddress(deliveryAddress, title);
      }),
    );
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.billTo').pipe(
      map((title) => {
        return this.getFormattedAddress(billingAddress, title);
      }),
    );
  }

  getFormattedAddress(address: Address, title: string) {
    let region = '';
    if (address.region && address.region.name) {
      region = address.region.name + ', ';
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = address.firstName + ' ' + address.lastName;
    }

    return {
      title,
      textBold: '',
      text: [
        name,
        address.companyName,
        address.line1,
        address.postalCode + ' ' + address.town,
        region + address.country.name,
        address.phone,
      ],
    };
  }

  getDeliveryModeCardContent(deliveryMode: DeliveryMode): Observable<Card> {
    return this.translationService
      .translate('orderDetails.shippingMethod')
      .pipe(
        filter(() => Boolean(deliveryMode)),
        map((textTitle) => ({
          title: textTitle,
          textBold: deliveryMode.name,
          text: [
            deliveryMode.description,
            deliveryMode.deliveryCost?.formattedValue
              ? deliveryMode.deliveryCost?.formattedValue
              : '',
          ],
        })),
      );
  }
}
