import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import { CmsAddToCartComponent, EventService } from '@spartacus/core';
import {
  ActiveCartFacade,
  CartUiEventAddToCart,
} from '@spartacus/cart/base/root';

@Component({
  selector: 'jaga-add-to-cart',
  templateUrl: './jaga-add-to-cart.component.html',
})
export class JagaAddToCartComponent extends AddToCartComponent {
  Math = Math;
  increment: boolean;

  @Input() confirmation: 'MODAL' | 'NONE' = 'MODAL';

  addToCartForm = new FormGroup({
    quantity: new FormControl(1),
  });

  constructor(
    currentProductService: CurrentProductService,
    cd: ChangeDetectorRef,
    activeCartService: ActiveCartFacade,
    component: CmsComponentData<CmsAddToCartComponent>,
    eventService: EventService
  ) {
    super(
      currentProductService,
      cd,
      activeCartService,
      component,
      eventService
    );
  }

  addToCart() {
    const quantity = this.addToCartForm.get('quantity').value;
    if (!this.productCode || quantity <= 0) {
      return;
    }

    this.activeCartService
      .getEntries()
      .pipe(take(1))
      .subscribe((cartEntries) => {
        this.activeCartService.addEntry(this.productCode, quantity);

        // A CartUiEventAddToCart is dispatched.  This event is intended for the UI
        // responsible to provide feedback about what was added to the cart, like
        // the added to cart dialog.
        //
        // Because we call activeCartService.getEntries() before, we can be sure the
        // cart library is loaded already and that the event listener exists.
        this.eventService.dispatch(
          this.createCartUiEventAddToCart(
            this.productCode,
            quantity,
            cartEntries.length
          )
        );
      });
  }

  protected createCartUiEventAddToCart(
    productCode: string,
    quantity: number,
    numberOfEntriesBeforeAdd: number
  ) {
    const newEvent = new CartUiEventAddToCart();
    newEvent.productCode = productCode;
    newEvent.quantity = quantity;
    newEvent.numberOfEntriesBeforeAdd = numberOfEntriesBeforeAdd;
    return newEvent;
  }
}
