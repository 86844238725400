import { Component } from '@angular/core';
import {
  CartItemComponent,
  CartItemContextSource,
} from '@spartacus/cart/base/components';
import { CartItemContext } from '@spartacus/cart/base/root';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { ItemCounterModule, MediaModule } from '@spartacus/storefront';
import { I18nModule, UrlModule } from '@spartacus/core';

@Component({
  selector: 'jaga-cart-item',
  templateUrl: './jaga-cart-item.component.html',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    MediaModule,
    UrlModule,
    I18nModule,
    ItemCounterModule,
  ],
  providers: [
    CartItemContextSource,
    { provide: CartItemContext, useExisting: CartItemContextSource },
  ],
})
export class JagaCartItemComponent extends CartItemComponent {
  constructor(protected cartItemContextSource: CartItemContextSource) {
    super(cartItemContextSource);
  }
}
