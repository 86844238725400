<div class="passwordInputContainer">
  <ng-content />

  <button
    class="toggle btn btn-sm btn-link"
    type="button"
    (click)="togglePasswordVisibility()"
  >
    <cx-icon class="far" [ngClass]="isHidden ? 'fa-eye' : 'fa-eye-slash'">
    </cx-icon>
  </button>
</div>
