import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OccEndpointsService } from '@spartacus/core';
import { DeliveryDateAdapter } from '../../core/connectors';
import { JagaCart } from '../../../../jaga-cart/base/core/models/jaga-cart.model';

@Injectable()
export class JagaOccCheckoutDeliveryDateAdapter implements DeliveryDateAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
  ) {}

  setDeliveryDate(
    userId: string,
    cartId: string,
    deliveryDate: string,
  ): Observable<JagaCart> {
    const payload = { deliveryDate } as JagaCart;

    const httpParams: HttpParams = new HttpParams().set(
      'fields',
      'DEFAULT,potentialProductPromotions,deliveryDate,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,remarks',
    );

    return this.http.put(
      this.getCartEndpoint(userId) + cartId + '/delivery-date',
      payload,
      {
        params: httpParams,
      },
    );
  }

  protected getCartEndpoint(userId: string): string {
    const cartEndpoint = 'users/' + userId + '/carts/';
    return this.occEndpoints.buildUrl(cartEndpoint);
  }
}
