import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  take,
  tap,
} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Card } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import {
  GlobalMessageService,
  isNotUndefined,
  TranslationService,
} from '@spartacus/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CheckoutPaymentTypeComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import {
  B2BPaymentTypeEnum,
  CheckoutPaymentTypeFacade,
} from '@spartacus/checkout/b2b/root';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
import { ActiveCartFacade, PaymentType } from '@spartacus/cart/base/root';
import { JagaCart } from '@jaga/cart';

@Component({
  selector: 'jaga-payment-type',
  templateUrl: './jaga-payment-type.component.html',
  styleUrls: ['./jaga-payment-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class JagaPaymentTypeComponent extends CheckoutPaymentTypeComponent {
  constructor(
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected checkoutStepService: CheckoutStepService,
    protected activeCartService: ActiveCartFacade,
    protected translation: TranslationService,
    protected activatedRoute: ActivatedRoute,
    protected globalMessageService: GlobalMessageService,
  ) {
    super(
      checkoutPaymentTypeFacade,
      checkoutStepService,
      activatedRoute,
      globalMessageService,
    );
  }

  typeSelected$: Observable<PaymentType> = this.activeCartService
    .getActive()
    .pipe(
      take(1),
      map((state) => state.paymentType),
      filter(isNotUndefined),
      distinctUntilChanged(),
      tap((selected) => {
        this.typeSelected = selected?.code;
        this.checkoutStepService.resetSteps();
        this.checkoutStepService.disableEnableStep(
          CheckoutStepType.PAYMENT_DETAILS,
          selected?.code === B2BPaymentTypeEnum.ACCOUNT_PAYMENT,
        );
      }),
    );

  cartPoNumber$ = this.activeCartService.getActive().pipe(
    take(1),
    filter(isNotUndefined),
    distinctUntilChanged(),
    map((cart) => cart.purchaseOrderNumber),
  );

  getBillingAddressCardContent(): Observable<Card> {
    return this.activeCartService.getActive().pipe(
      skipWhile((cart: JagaCart) => !cart.billingAddress),
      take(1),
      map((cart) => {
        const billingAddress = cart.billingAddress;
        let region = '';
        if (billingAddress.region && billingAddress.region.name) {
          region = `${billingAddress.region.name}, `;
        }

        let name = '';
        if (billingAddress.firstName && billingAddress.lastName) {
          name = `${billingAddress.firstName} ${billingAddress.lastName}`;
        }
        return {
          text: [
            name,
            billingAddress.companyName,
            billingAddress.line1,
            `${billingAddress.postalCode} ${billingAddress.town}`,
            region + billingAddress.country.name,
            billingAddress.phone,
          ],
        };
      }),
    );
  }
}
