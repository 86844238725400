import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  backOff,
  ConverterService,
  isJaloError,
  LoggerService,
  normalizeHttpError,
  OccEndpointsService,
} from '@spartacus/core';
import { JagaPaymentTypeListDTO } from '../../../base/occ/models/occ.models';
import {
  CHECKOUT_PAYMENT_TYPE_NORMALIZER,
  CheckoutPaymentTypeAdapter,
} from '@spartacus/checkout/b2b/core';
import { Cart, CART_NORMALIZER, PaymentType } from '@spartacus/cart/base/root';

const ENDPOINT_PAYMENT_TYPES = 'paymenttypes';

@Injectable()
export class JagaOccCheckoutPaymentTypeAdapter
  implements CheckoutPaymentTypeAdapter
{
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected logger: LoggerService
  ) {}

  getPaymentTypes(): Observable<PaymentType[]> {
    return this.http
      .get<JagaPaymentTypeListDTO>(
        this.occEndpoints.buildUrl(ENDPOINT_PAYMENT_TYPES)
      )
      .pipe(
        catchError((error) =>
          throwError(normalizeHttpError(error, this.logger))
        ),
        backOff({ shouldRetry: isJaloError }),
        map((paymentTypeList) => {
          return paymentTypeList.paymentTypes ?? [];
        }),
        this.converter.pipeableMany(CHECKOUT_PAYMENT_TYPE_NORMALIZER)
      );
  }

  setPaymentType(
    userId: string,
    cartId: string,
    paymentType: string,
    purchaseOrderNumber?: string
  ): Observable<Cart> {
    let httpParams = new HttpParams().set('paymentType', paymentType);
    if (purchaseOrderNumber !== undefined) {
      httpParams = httpParams.set('purchaseOrderNumber', purchaseOrderNumber);
    }
    /* tslint:disable:max-line-length */
    httpParams = httpParams.set(
      'fields',
      'DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,remarks,billingAddress(FULL)'
    );

    return this.http
      .put(
        `${this.getCartEndpoint(
          userId,
          cartId,
          paymentType,
          purchaseOrderNumber
        )}`,
        {},
        {
          params: httpParams,
        }
      )
      .pipe(
        catchError((error) =>
          throwError(normalizeHttpError(error, this.logger))
        ),
        backOff({ shouldRetry: isJaloError }),
        this.converter.pipeable(CART_NORMALIZER)
      );
  }

  protected getCartEndpoint(
    userId: string,
    cartId: string,
    paymentType: string,
    purchaseOrderNumber: string
  ): string {
    const cartEndpoint = `users/${userId}/carts/${cartId}/paymenttype`;
    const queryParams = { paymentType, purchaseOrderNumber };
    return this.occEndpoints.buildUrl(cartEndpoint, {
      urlParams: { userId, cartId },
    });
  }
}
