import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { JagaPaymentTypeComponent } from './jaga-payment-type.component';
import { CardModule, SpinnerModule } from '@spartacus/storefront';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';

@NgModule({
  declarations: [JagaPaymentTypeComponent],
  imports: [CommonModule, CardModule, I18nModule, SpinnerModule],
  exports: [JagaPaymentTypeComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutPaymentType: {
          component: JagaPaymentTypeComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    }),
  ],
})
export class JagaPaymentTypeModule {}
