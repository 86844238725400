/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { Provider } from '@angular/core';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { JagaCheckoutPaymentTypeService } from './jaga-checkout-payment-type.service';

export const facadeProviders: Provider[] = [
  JagaCheckoutPaymentTypeService,
  {
    provide: CheckoutPaymentTypeFacade,
    useExisting: JagaCheckoutPaymentTypeService,
  },
];
