import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Card } from '@spartacus/storefront';
import { ActivatedRoute } from '@angular/router';
import {
  getLastValueSync,
  GlobalMessageService,
  TranslationService,
  UserAddressService,
  UserCostCenterService,
} from '@spartacus/core';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
} from '@spartacus/checkout/base/root';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Address } from '@spartacus/core/src/model/address.model';
import { B2BCheckoutDeliveryAddressComponent } from '@spartacus/checkout/b2b/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CheckoutCostCenterFacade,
  CheckoutPaymentTypeFacade,
} from '@spartacus/checkout/b2b/root';
import { JagaAddress, JagaAddressType } from '@jaga/checkout';

export interface JagaCardWithAddress {
  card: Card;
  address: JagaAddress;
}

@Component({
  selector: 'jaga-shipping-address',
  templateUrl: './jaga-shipping-address.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaShippingAddressComponent
  extends B2BCheckoutDeliveryAddressComponent
  implements OnInit
{
  ngOnInit() {
    this.cards$ = this.createCards();

    if (
      this.checkoutPaymentTypeFacade &&
      this.userCostCenterService &&
      this.checkoutCostCenterFacade
    ) {
      this.subscriptions.add(
        this.checkoutPaymentTypeFacade
          .isAccountPayment()
          .pipe(distinctUntilChanged())
          .subscribe((isAccount) => (this.isAccountPayment = isAccount))
      );
    }

    if (!this.isGuestCheckout && !this.isAccountPayment) {
      this.userAddressService.loadAddresses();
    }
  }

  createCards(): Observable<JagaCardWithAddress[]> {
    return combineLatest([
      this.getSupportedAddresses(),
      this.selectedAddress$,
      this.translationService.translate(
        'checkoutAddress.defaultShippingAddress'
      ),
      this.translationService.translate('checkoutAddress.pickUpFromAddress'),
      this.translationService.translate('checkoutAddress.shipToThisAddress'),
      this.translationService.translate('addressCard.selected'),
      this.translationService.translate('checkoutAddress.shippingAddressTitle'),
      this.translationService.translate('checkoutAddress.pickUpAddressTitle'),
    ]).pipe(
      map(
        ([
          addresses,
          selected,
          textDefaultShippingAddressTitle,
          pickUpFromAddress,
          textShipToThisAddress,
          textSelected,
          shippingAddressTitle,
          pickUpAddressTitle,
        ]) => {
          // Select default address if none selected
          const allAddresses = addresses as Address[];
          if (
            allAddresses.length &&
            (!selected || Object.keys(selected).length === 0)
          ) {
            const defaultAddress = allAddresses.find(
              (address: Address) => address.defaultAddress
            );
            selected = defaultAddress;
            this.selectAddress(defaultAddress);
          }
          return allAddresses.map((address) => {
            const card = this.getJagaCardContent(
              address as JagaAddress,
              selected as JagaAddress,
              textDefaultShippingAddressTitle as string,
              pickUpFromAddress as string,
              textShipToThisAddress as string,
              textSelected as string,
              shippingAddressTitle as string,
              pickUpAddressTitle as string
            );
            return {
              address,
              card,
            };
          });
        }
      )
    );
  }

  getJagaCardContent(
    address: JagaAddress,
    selected: JagaAddress,
    textDefaultShippingAddressTitle: string,
    pickUpFromAddress: string,
    textShipToThisAddress: string,
    textSelected: string,
    shippingAddressTitle?: string,
    pickUpAddressTitle?: string
  ): Card {
    let region = '';
    if (address.region && address.region.name) {
      region = `${address.region.name}, `;
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = `${address.firstName} ${address.lastName}`;
    }
    return {
      title: this.isStoreAddress(address.addressType)
        ? pickUpAddressTitle
        : address.defaultAddress
        ? textDefaultShippingAddressTitle
        : shippingAddressTitle,
      text: [
        name,
        address.companyName,
        address.line1,
        `${address.postalCode} ${address.town}`,
        region + address.country.name,
        address.phone,
      ],
      actions: [
        {
          name: this.isStoreAddress(address.addressType)
            ? pickUpFromAddress
            : textShipToThisAddress,
          event: 'send',
        },
      ],
      header: selected && selected.id === address.id ? textSelected : '',
      img: this.isStoreAddress(address.addressType)
        ? 'fa fa-map-marker-alt'
        : 'fa fa-home',
    };
  }

  constructor(
    userAddressService: UserAddressService,
    checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    activatedRoute: ActivatedRoute,
    translationService: TranslationService,
    activeCartFacade: ActiveCartFacade,
    checkoutStepService: CheckoutStepService,
    checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    globalMessageService: GlobalMessageService,
    checkoutCostCenterFacade: CheckoutCostCenterFacade,
    checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    userCostCenterService: UserCostCenterService
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService,
      checkoutCostCenterFacade,
      checkoutPaymentTypeFacade,
      userCostCenterService
    );
  }

  isStoreAddress(addressType: JagaAddressType) {
    return addressType === JagaAddressType.Store;
  }

  selectAddress(address: Address): void {
    if (address?.id === getLastValueSync(this.selectedAddress$)?.id) {
      return;
    }

    this.setAddress(address);
  }
}
