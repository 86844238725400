import { JagaActiveCartService, JagaCart } from '@jaga/cart';
import { JagaRemarksService } from '../../../base/core/facade/jaga-remarks.service';
import { filter, map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Card } from '@spartacus/storefront/shared/components/card/card.component';
import {
  Address,
  Country,
  TranslationService,
  UserAddressService,
} from '@spartacus/core';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutStepType,
} from '@spartacus/checkout/base/root';
import {
  DeliveryMode,
  OrderEntry,
  PaymentType,
  PromotionLocation,
  PromotionResult,
} from '@spartacus/cart/base/root';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';

@Component({
  selector: 'jaga-review-submit',
  templateUrl: './jaga-review-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaReviewSubmitComponent implements OnInit {
  countryName$: Observable<string>;
  methodOfPayment: Observable<PaymentType>;
  orderPromotions$: Observable<PromotionResult[]>;
  promotionLocation: PromotionLocation = PromotionLocation.ActiveCart;

  showDetails = false;
  checkoutDeliveryMode = CheckoutStepType.DELIVERY_MODE;
  checkoutDeliveryAddress = CheckoutStepType.DELIVERY_ADDRESS;

  constructor(
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: JagaActiveCartService,
    protected translationService: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected remarksService: JagaRemarksService,
  ) {}

  get cart$(): Observable<JagaCart> {
    return this.activeCartService.getActive();
  }

  get entries$(): Observable<OrderEntry[]> {
    return this.activeCartService.getEntries();
  }

  get poNumber$(): Observable<string | undefined> {
    return this.activeCartService
      .getActive()
      .pipe(map((cart) => cart.purchaseOrderNumber));
  }

  get paymentType$(): Observable<PaymentType | undefined> {
    return this.activeCartService
      .getActive()
      .pipe(map((cart) => cart.paymentType));
  }

  get remarks() {
    return this.remarksService.getRemarks();
  }

  billingAddress$ = this.cart$.pipe(map((cart) => cart.billingAddress));

  deliveryAddress$: Observable<Address | undefined> =
    this.checkoutDeliveryAddressFacade.getDeliveryAddressState().pipe(
      filter((state) => !state.loading && !state.error),
      map((state) => state.data),
    );

  deliveryMode$: Observable<DeliveryMode | undefined> = combineLatest([
    this.checkoutDeliveryModesFacade.getSelectedDeliveryModeState(),
    this.checkoutDeliveryModesFacade.getSupportedDeliveryModes(),
  ]).pipe(
    map(([deliveryMode, supportedDeliveryModes]) => {
      return supportedDeliveryModes.filter((dm) => {
        return deliveryMode?.data?.code === dm.code;
      })[0];
    }),
  );

  ngOnInit() {
    this.countryName$ = this.deliveryAddress$.pipe(
      tap((address: Address) => {
        if (address?.country === null) {
          this.userAddressService.loadDeliveryCountries();
        }
      }),
      switchMap((address: Address) =>
        this.userAddressService.getCountry(address?.country?.isocode),
      ),
      map((country: Country) => country && country.name),
    );
  }

  getPoNumberCard(poNumber: string): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutReview.poNumber'),
      this.translationService.translate('checkoutPO.noPoNumber'),
    ]).pipe(
      map(([textTitle, noneTextTitle]) => {
        return {
          title: textTitle,
          textBold: poNumber ? poNumber : noneTextTitle,
        };
      }),
    );
  }

  getPaymentTypeCard(paymentType: PaymentType): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutProgress.methodOfPayment'),
      this.checkoutPaymentTypeFacade.getPaymentTypes(),
    ]).pipe(
      map(([textTitle, paymentTypes]) => {
        return {
          title: textTitle,
          textBold:
            paymentTypes.find((type) => type.code === paymentType.code)
              ?.displayName || '',
        };
      }),
    );
  }

  getRemarksCard(remarks: string): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutProgress.remarks'),
      this.translationService.translate('remarks.noRemarks'),
    ]).pipe(
      map(([textTitle, noRemarks]) => {
        return {
          title: textTitle,
          textBold: remarks || noRemarks,
        };
      }),
    );
  }

  getBillingAddressCard(
    billingAddress: Address,
    countryName: string,
  ): Observable<Card> {
    return combineLatest([
      this.translationService.translate('addressCard.billTo'),
    ]).pipe(
      skipWhile(() => !billingAddress),
      map(([title]) => {
        return this.getFormattedAddress(billingAddress, title, countryName);
      }),
    );
  }

  getShippingAddressCard(
    deliveryAddress: Address,
    countryName: string,
  ): Observable<Card> {
    return combineLatest([
      this.translationService.translate('addressCard.shipTo'),
    ]).pipe(
      map(([title]) => {
        return this.getFormattedAddress(deliveryAddress, title, countryName);
      }),
    );
  }

  getDeliveryModeCard(deliveryMode: DeliveryMode): Observable<Card> {
    return combineLatest([
      this.translationService.translate('checkoutShipping.shippingMethod'),
    ]).pipe(
      map(([textTitle]) => {
        return {
          title: textTitle,
          textBold: deliveryMode.name,
          text: [deliveryMode.description],
        };
      }),
    );
  }

  getCheckoutStepUrl(stepType: CheckoutStepType): string {
    const step = this.checkoutStepService.getCheckoutStep(stepType);
    return step && step.routeName;
  }

  getFormattedAddress(
    address: Address,
    title: string,
    countryName: string,
  ): Card {
    if (!countryName) {
      countryName = address.country.name;
    }

    let region = '';
    if (address.region && address.region.name) {
      region = `${address.region.name}, `;
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = `${address.firstName} ${address.lastName}`;
    }

    return {
      title,
      textBold: '',
      text: [
        name,
        address.companyName,
        address.line1,
        `${address.postalCode} ${address.town}`,
        region + countryName,
        address.phone,
      ],
    };
  }
}
