import { Component } from '@angular/core';
import {
  MiniCartComponent,
  MiniCartComponentService,
} from '@spartacus/cart/base/components/mini-cart';

@Component({
  selector: 'jaga-mini-cart',
  templateUrl: './jaga-mini-cart.component.html',
})
export class JagaMiniCartComponent extends MiniCartComponent {
  constructor(miniCartComponentService: MiniCartComponentService) {
    super(miniCartComponentService);
  }
}
