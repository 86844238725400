import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerCouponService } from '@spartacus/core';
import { FormBuilder, Validators } from '@angular/forms';
import { map, tap } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { CartCouponComponent } from '@spartacus/cart/base/components';
import {
  ActiveCartService,
  CartVoucherService,
} from '@spartacus/cart/base/core';
import { Cart } from '@spartacus/cart/base/root';

@Component({
  selector: 'jaga-cart-coupon',
  templateUrl: './jaga-cart-coupon.component.html',
  styles: [
    `
      :host {
        padding: 0;
      }
    `,
  ],
})
export class JagaCartCouponComponent
  extends CartCouponComponent
  implements OnInit, OnDestroy
{
  private jagaSubscription = new Subscription();

  constructor(
    cartVoucherService: CartVoucherService,
    formBuilder: FormBuilder,
    customerCouponService: CustomerCouponService,
    activeCartService: ActiveCartService
  ) {
    super(
      cartVoucherService,
      formBuilder,
      customerCouponService,
      activeCartService
    );

    this.couponForm = this.formBuilder.group({
      couponCode: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.cart$ = combineLatest([
      this.activeCartService.getActive(),
      this.activeCartService.getActiveCartId(),
    ]).pipe(
      tap(([cart, activeCardId]: [Cart, string]) => {
        this.cartId = activeCardId;
        this.getApplicableCustomerCoupons(cart, []);
      }),
      map(([cart]: [Cart, string]) => cart)
    );

    this.cartIsLoading$ = this.activeCartService
      .isStable()
      .pipe(map((loaded) => !loaded));

    this.cartVoucherService.resetAddVoucherProcessingState();

    this.jagaSubscription.add(
      this.cartVoucherService
        .getAddVoucherResultSuccess()
        .subscribe((success) => {
          this.onSuccess(success);
        })
    );

    this.jagaSubscription.add(
      this.cartVoucherService.getAddVoucherResultError().subscribe((error) => {
        this.onError(error);
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.jagaSubscription) {
      this.jagaSubscription.unsubscribe();
    }
  }
}
