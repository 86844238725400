import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  CardModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
} from '@spartacus/checkout/base/components';
import { JagaReviewSubmitComponent } from './jaga-review-submit.component';
import { JagaCartItemListComponent } from '@jaga/cart';

@NgModule({
  declarations: [JagaReviewSubmitComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    CardModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    JagaCartItemListComponent,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CheckoutReviewOrder: {
          component: JagaReviewSubmitComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class JagaReviewSubmitModule {}
