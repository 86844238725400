import { AfterViewInit, Component, ElementRef, Renderer2 } from '@angular/core';
import { IconModule } from '@spartacus/storefront';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'jaga-toggle-password',
  templateUrl: './jaga-toggle-password.component.html',
  styleUrls: ['./jaga-toggle-password.component.scss'],
  standalone: true,
  imports: [CommonModule, IconModule],
})
export class JagaTogglePasswordComponent implements AfterViewInit {
  input: HTMLInputElement;
  isHidden = true;

  constructor(
    private elem: ElementRef,
    private renderer: Renderer2,
  ) {}

  private readonly INPUT_TYPE_PASSWORD = 'password';
  private readonly INPUT_TYPE_TEXT = 'text';

  ngAfterViewInit(): void {
    this.input = this.elem.nativeElement.querySelector('input');
    if (!this.input) {
      throw new Error(`No input element found.`);
    }
  }

  togglePasswordVisibility() {
    this.renderer.setAttribute(
      this.input,
      'type',
      this.isHidden ? this.INPUT_TYPE_TEXT : this.INPUT_TYPE_PASSWORD,
    );
    this.isHidden = !this.isHidden;
  }
}
