import { Component, OnInit } from '@angular/core';
import { AuthService, RoutingService } from '@spartacus/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import {
  ActiveCartService,
  CartConfigService,
  SelectiveCartService,
} from '@spartacus/cart/base/core';

@Component({
  selector: 'jaga-cart-details',
  templateUrl: './jaga-cart-details.component.html',
})
export class JagaCartDetailsComponent
  extends CartDetailsComponent
  implements OnInit
{
  constructor(
    protected activeCartService: ActiveCartService,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected cartConfig: CartConfigService
  ) {
    super(
      activeCartService,
      selectiveCartService,
      authService,
      routingService,
      cartConfig
    );
  }
}
