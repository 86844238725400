import { createFeatureSelector, MemoizedSelector } from '@ngrx/store';
import {
  QUICK_ORDER_FEATURE,
  QuickOrderState,
  StateWithQuickOrder,
} from '../quick-order-state';

export const getQuickOrderState: MemoizedSelector<
  StateWithQuickOrder,
  QuickOrderState
> = createFeatureSelector<QuickOrderState>(QUICK_ORDER_FEATURE);
