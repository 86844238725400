import { NgModule } from '@angular/core';
import { JagaAddToCartModule } from './jaga-add-to-cart/jaga-add-to-cart.module';
import { JagaAddedToCartDialogModule } from './jaga-add-to-cart/jaga-added-to-cart-dialog/jaga-added-to-cart-dialog.module';
import { JagaCartCouponModule } from './jaga-cart-coupon/jaga-cart-coupon.module';
import { JagaCartTotalsCartModule } from './jaga-cart-totals/jaga-cart-totals.module';
import { JagaCartDetailsModule } from './jaga-cart-details/jaga-cart-details.module';
import { JagaMiniCartModule } from './jaga-mini-cart/jaga-mini-cart.module';
import { OutletModule, PAGE_LAYOUT_HANDLER } from '@spartacus/storefront';
import {
  ActiveCartOrderEntriesContext,
  CartPageLayoutHandler,
} from '@spartacus/cart/base/components';
import { CommonModule } from '@angular/common';
import { ActiveCartOrderEntriesContextToken } from '@spartacus/cart/base/root';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    JagaAddToCartModule,
    JagaCartCouponModule,
    JagaCartTotalsCartModule,
    JagaCartDetailsModule,
    JagaMiniCartModule,
    JagaAddedToCartDialogModule,
    OutletModule.forChild(),
  ],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: CartPageLayoutHandler,
      multi: true,
    },
    {
      provide: ActiveCartOrderEntriesContextToken,
      useExisting: ActiveCartOrderEntriesContext,
    },
  ],
})
export class JagaCartComponentsModule {}
