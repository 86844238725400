import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  JAGA_RESET_PASSWORD_ERRORS,
  JagaResetPasswordState,
} from '../../../jaga-user/store/jaga-reset-password-state.model';
import { Subscription } from 'rxjs';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'jaga-password-requirements',
  templateUrl: './jaga-password-requirements.component.html',
  styleUrls: ['./jaga-password-requirements.component.scss'],
})
export class JagaPasswordRequirementsComponent implements OnInit, OnDestroy {
  readonly requirements = ['minLength', 'uppercase', 'number', 'mustMatch'];

  private subscription: Subscription;

  errors: ValidationErrors;

  constructor(
    private store: Store<JagaResetPasswordState>,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription = this.store
      .pipe(select(JAGA_RESET_PASSWORD_ERRORS))
      .subscribe((errors: ValidationErrors) => {
        this.errors = errors;
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  getValidationClass(requirement: string): string {
    if (this.errors) {
      return this.errors.hasOwnProperty(requirement)
        ? 'requirement--failed'
        : 'requirement--passed';
    } else {
      return null;
    }
  }
}
