import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { JagaCheckoutOrderSummaryComponent } from './jaga-checkout-order-summary.component';
import { JagaOrderSummaryModule } from '../../../../jaga-cart/jaga-cart-shared/jaga-order-summary/jaga-order-summary.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [JagaCheckoutOrderSummaryComponent],
  imports: [CommonModule, JagaOrderSummaryModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutOrderSummary: {
          component: JagaCheckoutOrderSummaryComponent,
        },
      },
    }),
  ],
})
export class JagaCheckoutOrderSummaryModule {}
