import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JagaRemarksAdapter } from './jagaRemarksAdapter';
import { JagaCart } from '../../../../../jaga-cart/base/core/models/jaga-cart.model';

@Injectable({
  providedIn: 'root',
})
export class JagaRemarksConnector {
  constructor(protected adapter: JagaRemarksAdapter) {}

  getRemarks() {
    return this.adapter.getRemarks();
  }

  setRemarks(
    userId: string,
    cartId: string,
    remarks: string,
  ): Observable<JagaCart> {
    return this.adapter.setRemarks(userId, cartId, remarks);
  }
}
