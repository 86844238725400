import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { JagaOccCheckoutDeliveryDateAdapter } from './adapters/jaga-occ-checkout-delivery-date-adapter.service';

import { provideDefaultConfig } from '@spartacus/core';
import { jagaB2bOccConfig } from './config';
import {
  CheckoutAdapter,
  CheckoutDeliveryAddressAdapter,
  CheckoutDeliveryModesAdapter,
  CheckoutPaymentAdapter,
} from '@spartacus/checkout/base/core';
import { DeliveryDateAdapter, JagaRemarksAdapter } from '../core/connectors';
import {
  OccCheckoutDeliveryAddressAdapter,
  OccCheckoutDeliveryModesAdapter,
  OccCheckoutPaymentAdapter,
} from '@spartacus/checkout/base/occ';
import { JagaOccCheckoutAdapter } from './adapters/jaga-occ-checkout.adapter';
import { JagaOccCheckoutRemarksAdapter } from './adapters/jaga-occ-checkout-remarks.adapter';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(jagaB2bOccConfig),
    {
      provide: CheckoutAdapter,
      useClass: JagaOccCheckoutAdapter,
    },
    {
      provide: CheckoutDeliveryAddressAdapter,
      useClass: OccCheckoutDeliveryAddressAdapter,
    },
    {
      provide: CheckoutDeliveryModesAdapter,
      useClass: OccCheckoutDeliveryModesAdapter,
    },
    {
      provide: CheckoutPaymentAdapter,
      useClass: OccCheckoutPaymentAdapter,
    },
    JagaOccCheckoutDeliveryDateAdapter,
    {
      provide: DeliveryDateAdapter,
      useClass: JagaOccCheckoutDeliveryDateAdapter,
    },
    {
      provide: JagaRemarksAdapter,
      useClass: JagaOccCheckoutRemarksAdapter,
    },
    {
      provide: DatePipe,
    },
  ],
})
export class JagaCheckoutOccModule {}
