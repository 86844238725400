<div class="row mt-3">
  <div class="col-md-6 order-md-2">
    <jaga-password-requirements> </jaga-password-requirements>
  </div>

  <div class="col-md-6 order-md-1">
    <form
      (ngSubmit)="resetPassword()"
      [formGroup]="resetPasswordForm"
      class="cx-reset-password-form-component"
    >
      <div class="form-group">
        <label>
          <span class="label-content">{{
            "register.emailAddress.label" | cxTranslate
          }}</span>
        </label>
        <input
          class="form-control"
          name="email"
          type="text"
          value="{{ email }}"
          disabled
        />
      </div>
      <div class="form-group">
        <label>
          <span class="label-content">{{
            "register.newPassword" | cxTranslate
          }}</span>
          <jaga-toggle-password>
            <input
              [formControlName]="passwordFormName"
              class="form-control"
              name="password"
              placeholder="{{ 'register.password.placeholder' | cxTranslate }}"
              type="password"
            />
          </jaga-toggle-password>
        </label>
      </div>

      <div class="form-group">
        <label>
          <span class="label-content">{{
            "register.passwordMinRequirements" | cxTranslate
          }}</span>
          <jaga-toggle-password>
            <input
              [formControlName]="rePasswordFormName"
              class="form-control"
              name="confirmpassword"
              placeholder="{{
                'register.confirmPassword.placeholder' | cxTranslate
              }}"
              type="password"
            />
          </jaga-toggle-password>
        </label>
      </div>

      <div class="form-group">
        <button class="btn btn-block btn-primary" type="submit">
          {{ "register.resetPassword" | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
