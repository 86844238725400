import { JagaOccConfig } from '../../../base/occ/config/jaga-occ-config';

export const jagaB2bOccConfig: JagaOccConfig = {
  backend: {
    occ: {
      endpoints: {
        // user: 'users/${userId}',
        // addEntries: 'users/${userId}/carts/${cartId}/entries',
        createDeliveryAddress:
          'users/${userId}/carts/${cartId}/addresses/delivery',
        setDeliveryAddress:
          'users/${userId}/carts/${cartId}/addresses/delivery',
        deliveryModes: 'users/${userId}/carts/${cartId}/deliverymodes',
        clearDeliveryMode: 'users/${userId}/carts/${cartId}/deliverymode',
        setDeliveryMode: 'users/${userId}/carts/${cartId}/deliverymode',
        getCheckoutDetails:
          'users/${userId}/carts/${cartId}?fields=deliveryAddress(FULL),deliveryMode(FULL),paymentInfo(FULL)',
        placeOrder:
          'users/${userId}/orders?termsChecked=true&fields=FULL,billingAddress(FULL),paymentType(FULL)',
        // setCartPaymentDetails: 'users/${userId}/carts/${cartId}/paymentdetails',
        // setCartPaymentType: 'users/${userId}/carts/${cartId}/paymenttype',
        // replenishmentOrderDetails:
        //   'users/${userId}/replenishmentOrders/${replenishmentOrderCode}?fields=FULL,costCenter(FULL),purchaseOrderNumber,paymentType,user',
        // replenishmentOrderDetailsHistory:
        //   'users/${userId}/replenishmentOrders/${replenishmentOrderCode}/orders',
        // cancelReplenishmentOrder:
        //   'users/${userId}/replenishmentOrders/${replenishmentOrderCode}?fields=FULL,costCenter(FULL),purchaseOrderNumber,paymentType,user',
        // replenishmentOrderHistory:
        //   'users/${userId}/replenishmentOrders?fields=FULL,replenishmentOrders(FULL, purchaseOrderNumber)',
      },
    },
  },
};
