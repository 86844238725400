<div class="row">
  <div class="col-12">
    <h5 class="h5">{{ "checkoutPO.poNumber" | cxTranslate }}</h5>
    <label class="po-number-container">
      <input
        #poNumber
        class="form-control"
        maxlength="18"
        placeholder="{{ 'checkoutPO.placeholder' | cxTranslate }}"
        type="text"
        value="{{ cartPoNumber$ | async }}"
      />
    </label>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <ng-container *ngIf="paymentTypes$ | async as paymentTypes">
      <ng-container
        *ngIf="
          !!paymentTypes.length &&
            (typeSelected$ | async) &&
            !(isUpdating$ | async);
          else loading
        "
      >
        <h5 class="h5">{{ "paymentTypes.title" | cxTranslate }}</h5>
        <div *ngFor="let type of paymentTypes$ | async" class="form-check">
          <input
            (change)="changeType(type.code)"
            [checked]="type.code == typeSelected"
            [value]="type.code"
            aria-checked="true"
            class="form-check-input"
            id="paymentType-{{ type.code }}"
            role="radio"
            type="radio"
          />
          <label
            class="cx-payment-type-label form-check-label form-radio-label"
            for="paymentType-{{ type.code }}"
          >
            <div class="cx-payment-type">
              {{ type.displayName }}
            </div>
          </label>
        </div>
        <div
          *ngIf="typeSelected === 'PREPAYMENT'"
          class="prepayment-warning"
          [@fadeInOut]
        >
          {{ "paymentMethods.prepaymentWarning" | cxTranslate }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-12 jaga-billing-address">
    <h5 class="h5">{{ "checkoutAddress.billingAddress" | cxTranslate }}</h5>
    <cx-card [border]="true" [content]="getBillingAddressCardContent() | async">
    </cx-card>
  </div>
</div>

<div class="cx-checkout-btns row">
  <div class="col-12 col-md-6">
    <button (click)="back()" class="btn btn-block btn-action">
      {{ "checkout.backToCart" | cxTranslate }}
    </button>
  </div>
  <div class="col-12 col-md-6">
    <button (click)="next()" class="btn btn-block btn-primary">
      {{ "common.continue" | cxTranslate }}
    </button>
  </div>
</div>

<ng-template #loading>
  <cx-spinner class="cx-spinner"></cx-spinner>
</ng-template>
