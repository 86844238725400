<ng-container *ngIf="order$ | async as order">
  <!-- unconsigned entries -->
  <ng-container>
    <div class="cx-list row">
      <div class="cx-list-item col-12">
        <jaga-cart-item-list
          [items]="order?.unconsignedEntries"
          [readonly]="true"
          [promotionLocation]="promotionLocation"
        ></jaga-cart-item-list>
      </div>
    </div>
  </ng-container>
</ng-container>
