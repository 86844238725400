import { NgModule } from '@angular/core';
import {
  CheckoutConnector,
  CheckoutDeliveryAddressConnector,
  CheckoutDeliveryModesConnector,
  CheckoutPageMetaResolver,
  CheckoutPaymentConnector,
} from '@spartacus/checkout/base/core';
import { facadeProviders } from './facade/facade-providers';
import { PageMetaResolver } from '@spartacus/core';
import { DeliveryDateConnector, JagaRemarksConnector } from './connectors';

@NgModule({
  providers: [
    ...facadeProviders,
    CheckoutDeliveryAddressConnector,
    CheckoutDeliveryModesConnector,
    CheckoutPaymentConnector,
    CheckoutConnector,
    JagaRemarksConnector,
    DeliveryDateConnector,
    CheckoutPageMetaResolver,
    {
      provide: PageMetaResolver,
      useExisting: CheckoutPageMetaResolver,
      multi: true,
    },
  ],
})
export class JagaCheckoutCoreModule {}
