<pinch-zoom *ngIf="media?.src"
            #pinchZoom
            [wheel]="false"
            (dblclick)="toggleZoom()"
            disableZoomControl="disable"
            backgroundColor="transparent">
  <img
    (error)="errorHandler()"
    (load)="loadHandler()"
    [attr.alt]="media.alt"
    [attr.src]="media.src"
    [attr.srcset]="media.srcset"
  />
</pinch-zoom>
