import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardModule,
  FormErrorsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
} from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { JagaUpdatePasswordComponent } from './jaga-update-password.component';
import { JagaUpdatePasswordFormComponent } from './jaga-update-password-form/jaga-update-password-form.component';
import { jagaOccUserConfig } from '../../config/jaga-occ-user-config';
import { JagaCommonModule } from '../../../jaga-common/jaga-common.module';
import { JagaTogglePasswordComponent } from '@jaga/common';

@NgModule({
  declarations: [JagaUpdatePasswordComponent, JagaUpdatePasswordFormComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig(jagaOccUserConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        UpdatePasswordComponent: {
          component: JagaUpdatePasswordComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
    SpinnerModule,
    I18nModule,
    ReactiveFormsModule,
    FormErrorsModule,
    JagaCommonModule,
    CardModule,
    JagaTogglePasswordComponent,
  ],
})
export class JagaUpdatePasswordModule {}
