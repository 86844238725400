import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Country,
  GlobalMessageService,
  Region,
  RoutingService,
  Title,
  UserAddressService,
} from '@spartacus/core';
import {
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  CustomFormValidators,
  ICON_TYPE,
  sortTitles,
} from '@spartacus/storefront';
import { finalize, map, take, tap } from 'rxjs/operators';
import { JagaUserSignUp } from '../../models/user-sign-up.model';
import { AreaOfExpertise, NumberOfEmployees } from '../../models/constants';
import { JagaUserService } from '../../services/jaga-user.service';
import { JagaCustomFormValidators } from '@jaga/util';
import {
  UserProfileFacade,
  UserRegisterFacade,
} from '@spartacus/user/profile/root';

@Component({
  selector: 'jaga-register-customer',
  templateUrl: './jaga-register-customer.component.html',
  styleUrls: ['./jaga-register-customer.component.scss'],
})
export class JagaRegisterCustomerComponent implements OnInit, OnDestroy {
  titles$: Observable<Title[]>;
  regions$: Observable<Region[]>;
  _loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this._loading$.asObservable();

  countries: Country[];

  AreaOfExpertise = AreaOfExpertise;
  areaOfExpertiseOptions: string[] = Object.keys(this.AreaOfExpertise);

  NumberOfEmployees = NumberOfEmployees;
  numberOfEmployeesOptions: string[] = Object.keys(this.NumberOfEmployees);

  ICON_TYPE = ICON_TYPE;

  readonly vatNumberCustomerNumberFormGroupName: string =
    'vatNumberCustomerNumberFormGroup';
  readonly vatNumberFormName: string = 'vatNumber';
  readonly customerNumberFormName: string = 'customerNumber';

  readonly existingCustomerFormName: string = 'existingCustomer';

  readonly companyNameFormName: string = 'companyName';
  readonly numberOfInstallationsFormName: string = 'numberOfInstallations';
  readonly areaOfExpertiseFormName: string = 'areaOfExpertise';
  readonly countryFormName: string = 'country';
  readonly addressLine1FormName: string = 'addressLine1';
  readonly postalCodeFormName: string = 'postalCode';
  readonly cityFormName: string = 'city';
  readonly regionFormName: string = 'region';
  readonly numberOfEmployeesFormName: string = 'numberOfEmployees';
  readonly titleFormName: string = 'titleCode';
  readonly firstNameFormName: string = 'firstName';
  readonly lastNameFormName: string = 'lastName';
  readonly emailFormName: string = 'email';
  readonly billingEmailFormName: string = 'billingEmail';
  readonly orderConfirmationEmailFormName: string = 'orderConfirmationEmail';
  readonly phoneNumberFormName: string = 'phoneNumber';
  readonly termsAndConditionsFormName: string = 'termsAndConditions';

  vatNumberCustomerNumberForm: FormGroup = this.fb.group(
    {
      [this.vatNumberFormName]: new FormControl('', Validators.required),
      [this.customerNumberFormName]: [''],
    },
    { updateOn: 'blur' },
  );

  registerForm: FormGroup = this.fb.group({
    [this.existingCustomerFormName]: ['no', Validators.required],

    [this.vatNumberCustomerNumberFormGroupName]:
      this.vatNumberCustomerNumberForm,

    [this.companyNameFormName]: ['', Validators.required],
    [this.billingEmailFormName]: [
      '',
      [Validators.required, CustomFormValidators.emailValidator],
    ],
    [this.orderConfirmationEmailFormName]: [
      '',
      [Validators.required, CustomFormValidators.emailValidator],
    ],
    [this.numberOfInstallationsFormName]: ['', Validators.required],
    [this.areaOfExpertiseFormName]: ['', Validators.required],
    [this.countryFormName]: ['', Validators.required],
    [this.addressLine1FormName]: ['', Validators.required],
    [this.postalCodeFormName]: new FormControl(
      '',
      [Validators.required],
      JagaCustomFormValidators.newPostalCodeValidator(this.jagaUserService),
    ),
    [this.cityFormName]: [
      '',
      [Validators.required, JagaCustomFormValidators.validateString],
    ],
    [this.regionFormName]: ['', Validators.required],

    [this.numberOfEmployeesFormName]: ['', Validators.required],
    [this.titleFormName]: ['', Validators.required],
    [this.firstNameFormName]: [
      '',
      [Validators.required, JagaCustomFormValidators.validateString],
    ],
    [this.lastNameFormName]: [
      '',
      [Validators.required, JagaCustomFormValidators.validateString],
    ],
    [this.emailFormName]: new FormControl(
      '',
      [Validators.required, CustomFormValidators.emailValidator],
      JagaCustomFormValidators.newCustomerEmailValidator(this.jagaUserService),
    ),
    [this.phoneNumberFormName]: [
      '',
      [Validators.required, JagaCustomFormValidators.validatePhoneNumber],
    ],

    [this.termsAndConditionsFormName]: [false, Validators.requiredTrue],
  });
  private subscription = new Subscription();

  constructor(
    protected userProfileFacade: UserProfileFacade,
    protected userRegisterFacade: UserRegisterFacade,
    protected jagaUserService: JagaUserService,
    protected userAddressService: UserAddressService,
    protected globalMessageService: GlobalMessageService,
    protected fb: FormBuilder,
    protected router: RoutingService,
  ) {}

  get existingCustomer(): boolean {
    return this.existingCustomerFormControl.value === 'yes';
  }

  get vatNumberValidator(): AsyncValidatorFn {
    return this.existingCustomer
      ? JagaCustomFormValidators.existingCustomerVatIdValidator(
          this.jagaUserService,
        )
      : JagaCustomFormValidators.newCustomerVatIdValidator(
          this.jagaUserService,
        );
  }

  get existingCustomerFormControl(): FormControl {
    return this.registerForm.get(this.existingCustomerFormName) as FormControl;
  }

  get vatNumberFormControl(): FormControl {
    return this.vatNumberCustomerNumberForm.get(
      this.vatNumberFormName,
    ) as FormControl;
  }

  get customerNumberFormControl(): FormControl {
    return this.vatNumberCustomerNumberForm.get(
      this.customerNumberFormName,
    ) as FormControl;
  }

  get companyNameFormControl(): FormControl {
    return this.registerForm.get(this.companyNameFormName) as FormControl;
  }

  get billingEmailFormControl(): FormControl {
    return this.registerForm.get(this.billingEmailFormName) as FormControl;
  }

  get orderConfirmationEmailFormControl(): FormControl {
    return this.registerForm.get(
      this.orderConfirmationEmailFormName,
    ) as FormControl;
  }

  get numberOfInstallationsFormControl(): FormControl {
    return this.registerForm.get(
      this.numberOfInstallationsFormName,
    ) as FormControl;
  }

  get areaOfExpertiseFormControl(): FormControl {
    return this.registerForm.get(this.areaOfExpertiseFormName) as FormControl;
  }

  get countryFormControl(): FormControl {
    return this.registerForm.get(this.countryFormName) as FormControl;
  }

  get addressLine1FormControl(): FormControl {
    return this.registerForm.get(this.addressLine1FormName) as FormControl;
  }

  get postalCodeFormControl(): FormControl {
    return this.registerForm.get(this.postalCodeFormName) as FormControl;
  }

  get cityFormControl(): FormControl {
    return this.registerForm.get(this.cityFormName) as FormControl;
  }

  get regionFormControl(): FormControl {
    return this.registerForm.get(this.regionFormName) as FormControl;
  }

  get numberOfEmployeesFormControl(): FormControl {
    return this.registerForm.get(this.numberOfEmployeesFormName) as FormControl;
  }

  get titleFormControl(): FormControl {
    return this.registerForm.get(this.titleFormName) as FormControl;
  }

  get firstNameFormControl(): FormControl {
    return this.registerForm.get(this.firstNameFormName) as FormControl;
  }

  get lastNameFormControl(): FormControl {
    return this.registerForm.get(this.lastNameFormName) as FormControl;
  }

  get emailFormControl(): FormControl {
    return this.registerForm.get(this.emailFormName) as FormControl;
  }

  get phoneNumberFormControl(): FormControl {
    return this.registerForm.get(this.phoneNumberFormName) as FormControl;
  }

  get termsAndConditionsFormControl(): FormControl {
    return this.registerForm.get(
      this.termsAndConditionsFormName,
    ) as FormControl;
  }

  ngOnInit(): void {
    this.titles$ = this.userProfileFacade
      .getTitles()
      .pipe(map((titles) => titles.sort(sortTitles)));

    this.setupCountryChangeListener();

    this.subscription.add(
      this.userAddressService
        .getDeliveryCountries()
        .pipe(
          tap((countries: Country[]) => {
            // If the store is empty fetch countries. This is also used when changing language.
            if (countries.length === 0) {
              this.userAddressService.loadDeliveryCountries();
            }
          }),
        )
        .subscribe((countries: Country[]) => {
          this.countries = countries;
          if (countries.length > 0) {
            this.countryFormControl.setValue(countries[0].isocode);
          }
        }),
    );

    this.setupValidators();
  }

  submitForm(): void {
    this.emailFormControl.clearAsyncValidators();
    if (this.registerForm.valid) {
      this._loading$.next(true);
      this.registerUser();
    } else {
      this.registerForm.markAllAsTouched();
    }
  }

  registerUser(): void {
    this.userRegisterFacade
      .register(this.collectDataFromRegisterForm())
      .pipe(
        take(1),
        map((user) => !!user),
        finalize(() => {
          this._loading$.next(false);
        }),
      )
      .subscribe((success) => this.onRegisterUserSuccess(success));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setVatNumberValidator(): void {
    this.vatNumberFormControl.setAsyncValidators(this.vatNumberValidator);
    this.vatNumberFormControl.updateValueAndValidity();
  }

  private collectDataFromRegisterForm(): JagaUserSignUp {
    const {
      firstName,
      lastName,
      email,
      companyName,
      numberOfInstallations,
      areaOfExpertise,
      billingEmail,
      orderConfirmationEmail,
      country,
      addressLine1,
      postalCode,
      city,
      region,
      numberOfEmployees,
      titleCode,
      phoneNumber,
    } = this.registerForm.value;

    const { vatNumber, customerNumber } =
      this.registerForm.value.vatNumberCustomerNumberFormGroup;

    if (!this.existingCustomer) {
      return {
        existingCustomer: this.existingCustomer,
        vatID: vatNumber.trim(),
        companyName,
        billingEmail,
        orderConfirmationEmail,
        companyAddressCountryIso: country,
        companyAddressStreet: addressLine1,
        companyAddressPostalCode: postalCode,
        companyAddressCity: city,
        companyAddressRegion: region,
        numberOfInstallations,
        areaOfExpertise: this.AreaOfExpertise[areaOfExpertise],
        numberOfEmployees: this.NumberOfEmployees[numberOfEmployees],
        titleCode,
        firstName,
        lastName,
        telephone: phoneNumber,
        email: email.toLowerCase(),
      };
    } else {
      return {
        existingCustomer: this.existingCustomer,
        vatID: vatNumber.trim(),
        customerNumber: customerNumber.trim(),
        titleCode,
        firstName,
        lastName,
        telephone: phoneNumber,
        email: email.toLowerCase(),
        uid: email.toLowerCase(),
      };
    }
  }

  private onRegisterUserSuccess(success: boolean): void {
    if (success) {
      this.router.go('registerConfirmation');
    }
  }

  private setupValidators(): void {
    this.subscription.add(
      this.existingCustomerFormControl.valueChanges.subscribe(
        (value: string) => {
          if (value === 'yes') {
            this.companyNameFormControl.clearValidators();
            this.customerNumberFormControl.setValidators(Validators.required);
            this.billingEmailFormControl.clearValidators();
            this.orderConfirmationEmailFormControl.clearValidators();
            this.numberOfInstallationsFormControl.clearValidators();
            this.areaOfExpertiseFormControl.clearValidators();
            this.countryFormControl.clearValidators();
            this.addressLine1FormControl.clearValidators();
            this.postalCodeFormControl.clearValidators();
            this.postalCodeFormControl.clearAsyncValidators();
            this.cityFormControl.clearValidators();
            this.regionFormControl.clearValidators();
            this.numberOfEmployeesFormControl.clearValidators();
            this.vatNumberCustomerNumberForm.setAsyncValidators(
              JagaCustomFormValidators.vatIdMustMatchCustomerNumberValidator(
                this.jagaUserService,
                this.vatNumberFormName,
                this.customerNumberFormName,
              ),
            );
          } else {
            this.companyNameFormControl.setValidators(Validators.required);
            this.customerNumberFormControl.clearValidators();
            this.billingEmailFormControl.setValidators([
              Validators.required,
              CustomFormValidators.emailValidator,
            ]);
            this.orderConfirmationEmailFormControl.setValidators([
              Validators.required,
              CustomFormValidators.emailValidator,
            ]);
            this.numberOfInstallationsFormControl.setValidators(
              Validators.required,
            );
            this.areaOfExpertiseFormControl.setValidators(Validators.required);
            this.countryFormControl.setValidators(Validators.required);
            this.addressLine1FormControl.setValidators(Validators.required);
            this.postalCodeFormControl.setValidators(Validators.required);
            this.cityFormControl.setValidators(Validators.required);
            this.regionFormControl.setValidators(Validators.required);
            this.numberOfEmployeesFormControl.setValidators(
              Validators.required,
            );
            this.vatNumberCustomerNumberForm.clearAsyncValidators();
          }
          this.vatNumberFormControl.setAsyncValidators(this.vatNumberValidator);
          this.clearAllFormValidationErrors();

          this.registerForm.updateValueAndValidity();
        },
      ),
    );
  }

  private clearAllFormValidationErrors(): void {
    Object.keys(this.registerForm.controls)
      .filter((key) => key !== this.existingCustomerFormName)
      .forEach((key) => {
        this.registerForm.get(key).markAsUntouched();
        this.registerForm.get(key).updateValueAndValidity();
      });

    Object.keys(this.vatNumberCustomerNumberForm.controls).forEach((key) => {
      this.vatNumberCustomerNumberForm.get(key).markAsUntouched();
      this.vatNumberCustomerNumberForm.get(key).updateValueAndValidity();
    });
  }

  private setupCountryChangeListener(): void {
    this.subscription.add(
      this.countryFormControl.valueChanges.subscribe(
        (countryIsoCode: string) => {
          if (this.regionFormControl.value) {
            this.regionFormControl.reset();
          }
          this.regions$ = this.userAddressService.getRegions(countryIsoCode);
        },
      ),
    );
  }
}
