import { NgModule } from '@angular/core';
import { IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import { CommonModule } from '@angular/common';
import { JagaAddressCardComponent } from './jaga-address-card.component';

@NgModule({
  imports: [CommonModule, I18nModule, IconModule],
  declarations: [JagaAddressCardComponent],
  exports: [JagaAddressCardComponent],
})
export class JagaAddressCardModule {}
