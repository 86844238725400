import { NgModule } from '@angular/core';
import { HttpErrorHandler } from '@spartacus/core';
import { MollieBadRequestHandler } from './handlers/mollie-bad-request/mollie-bad-request.handler';

@NgModule({
  providers: [
    {
      provide: HttpErrorHandler,
      useExisting: MollieBadRequestHandler,
      multi: true,
    },
  ],
})
export class MollieHttpInterceptorsModule {}
