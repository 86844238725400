import { Injectable } from '@angular/core';
import { combineLatest, Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  backOff,
  ConverterService,
  isJaloError,
  LoggerService,
  normalizeHttpError,
  OccEndpointsService,
  UserIdService,
} from '@spartacus/core';
import { JagaRemarksAdapter } from '../../core/connectors';
import { JagaCart } from '../../../../jaga-cart/base/core/models/jaga-cart.model';
import { catchError, map, tap } from 'rxjs/operators';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CHECKOUT_REMARKS_NORMALIZER } from '../../core/connectors/remarks/converters';

@Injectable()
export class JagaOccCheckoutRemarksAdapter implements JagaRemarksAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected userIdService: UserIdService,
    protected activeCartFacade: ActiveCartFacade,
    protected logger: LoggerService,
  ) {}

  getRemarks() {
    return this.http.get<string>(this.getRemarksEndpoint()).pipe(
      catchError((error) => throwError(normalizeHttpError(error, this.logger))),
      backOff({ shouldRetry: isJaloError }),
      map((remarks) => remarks ?? ''),
      this.converter.pipeable(CHECKOUT_REMARKS_NORMALIZER),
    );
  }

  protected getRemarksEndpoint(): string {
    combineLatest([
      this.userIdService.getUserId(),
      this.activeCartFacade.getActive(),
    ]).pipe(
      tap(([userId, cartId]) => {
        const endpoint = `${this.getCartEndpoint(userId)}${cartId}/remarks`;
      }),
    );

    return this.occEndpoints.buildUrl('remarks');
  }

  setRemarks(
    userId: string,
    cartId: string,
    remarks: string,
  ): Observable<JagaCart> {
    const payload = { remarks } as JagaCart;

    const httpParams: HttpParams = new HttpParams().set(
      'fields',
      'DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,remarks',
    );

    return this.http.put(
      `${this.getCartEndpoint(userId)}${cartId}/remarks`,
      payload,
      {
        params: httpParams,
      },
    );
  }

  protected getCartEndpoint(userId: string): string {
    const cartEndpoint = `users/${userId}/carts/`;
    return this.occEndpoints.buildUrl(cartEndpoint);
  }
}
