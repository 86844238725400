import { Component } from '@angular/core';
import { CardComponent } from '@spartacus/storefront';

@Component({
  selector: 'jaga-address-card',
  templateUrl: './jaga-address-card.component.html',
})
export class JagaAddressCardComponent extends CardComponent {
  constructor() {
    super();
  }
}
