import { NgModule } from '@angular/core';
import { JagaCheckoutComponentsModule } from './components/jaga-checkout-components.module';
import { JagaCheckoutCoreModule } from './core/jaga-checkout-core.module';
import { JagaCheckoutOccModule } from './occ/jaga-checkout-occ.module';

@NgModule({
  imports: [
    JagaCheckoutComponentsModule,
    JagaCheckoutCoreModule,
    JagaCheckoutOccModule,
  ],
})
export class JagaCheckoutModule {}
