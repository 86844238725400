<div class="cx-order-review-summary" *ngIf="order$ | async as order">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 col-lg-3">
        <div class="summary-card">
          <cx-card
            [content]="getAddressCardContent(order?.deliveryAddress) | async"
          ></cx-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-3">
        <div class="summary-card">
          <cx-card
            [content]="
              getBillingAddressCardContent(order?.billingAddress) | async
            "
          ></cx-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-3">
        <div class="summary-card">
          <cx-card
            [content]="getDeliveryModeCardContent(order?.deliveryMode) | async"
          ></cx-card>
        </div>
      </div>

      <div class="col-sm-12 col-md-4 col-lg-3">
        <div class="summary-card">
          <cx-card
            [content]="getPaymentTypeCardContent(order?.paymentType) | async"
          ></cx-card>
        </div>
      </div>
    </div>
  </div>
</div>
