<ng-container *ngIf="component.data$ | async as data">
  <div class="TopContent has-components">
    <!-- Header -->
    <h2>{{ "quickOrder.title" | cxTranslate }}</h2>
    <p>{{ "quickOrder.description" | cxTranslate }}</p>
    <div class="d-none d-md-block">
      <div class="quick-order-list-header row">
        <div class="quick-order-item-list col-md-3">
          {{ "quickOrder.productCode" | cxTranslate }}
        </div>
        <div class="quick-order-item-list col-md-4">
          {{ "quickOrder.productInfo" | cxTranslate }}
        </div>
        <div class="quick-order-list-price col-md-2 px-4">
          {{ "quickOrder.price" | cxTranslate }}
        </div>
        <div class="quick-order-list-qty col-md-2 px-4">
          {{ "quickOrder.quantity" | cxTranslate }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="entries$ | async as entries">
      <jaga-quick-order-item
        *ngIf="entries.length == 0"
        [entry]="demoEntry"
        [isDemo]="true"
      >
      </jaga-quick-order-item>
      <div *ngFor="let entry of entries">
        <jaga-quick-order-item [entry]="entry" [isDemo]="false">
        </jaga-quick-order-item>
      </div>

      <div class="row mx-md-0 my-1">
        <jaga-quick-order-search
          [disabled]="entries.length > data.maxRows"
          [maxSuggestions]="data.maxSuggestions"
          class="col-md-3"
        >
        </jaga-quick-order-search>
      </div>

      <p *ngIf="entries.length > data.maxRows" class="text-danger">
        {{
          "quickOrder.maxValidRows" | cxTranslate : { maxRows: data.maxRows }
        }}
      </p>
      <div class="py-3 form-group row justify-content-end">
        <div class="col-md-5 col-lg-4">
          <button
            (click)="clearEntries()"
            class="btn btn-block btn-secondary"
            type="button"
          >
            {{ "quickOrder.clear" | cxTranslate }}
          </button>
        </div>
        <div class="col-md-5 col-lg-4">
          <button
            (click)="addEntriesToCart(1, entries)"
            class="btn btn-block btn-primary"
            type="button"
          >
            {{ "addToCart.addToCart" | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
