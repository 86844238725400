import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { CardModule, PromotionsModule } from '@spartacus/storefront';
import { JagaOrderConfirmationOverviewComponent } from './components/jaga-order-confirmation-overview/jaga-order-confirmation-overview.component';
import { JagaOrderSummaryModule } from '@jaga/cart';
import { JagaOrderConfirmationTotalsComponent } from './components/jaga-order-confirmation-totals/jaga-order-confirmation-totals.component';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { JagaOrderConfirmationItemsComponentModule } from './components/jaga-order-confirmation-items-component/jaga-order-confirmation-items-component.module';

@NgModule({
  declarations: [
    JagaOrderConfirmationOverviewComponent,
    JagaOrderConfirmationTotalsComponent,
  ],
  imports: [
    CommonModule,
    CardModule,
    JagaOrderSummaryModule,
    JagaOrderConfirmationItemsComponentModule,
    PromotionsModule,
    I18nModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        OrderConfirmationOverviewComponent: {
          component: JagaOrderConfirmationOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
        OrderConfirmationTotalsComponent: {
          component: JagaOrderConfirmationTotalsComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    }),
  ],
})
export class JagaOrderConfirmationModule {}
