import { RoutingConfig } from '@spartacus/core';

export const jagaCheckoutB2bRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      checkout: { paths: ['checkout'] },
      checkoutPaymentType: { paths: ['checkout/payment-type'] },
      checkoutDeliveryAddress: { paths: ['checkout/shipping-address'] },
      checkoutDeliveryMode: { paths: ['checkout/delivery-mode'] },
      checkoutReviewOrder: { paths: ['checkout/review-order'] },
    },
  },
};
