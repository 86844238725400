import { MollieOrderConfirmationGuard } from './guards/mollie-order-confirmation.guard';
import { MollieHttpInterceptorsModule } from './http-interceptors/mollie-http-interceptors.module';
import { RouterModule } from '@angular/router';
import { provideDefaultConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { MollieOccConfig } from './config/mollie-occ-config';
import { OrderOverviewComponent } from '@spartacus/order/components';

export const defaultMollieOccConfig: MollieOccConfig = {
  backend: {
    occ: {
      endpoints: {
        molliePlaceOrder: 'users/${userId}/mollie/order/create',
      },
    },
  },
};

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'mollie/order/:orderCode',
        component: OrderOverviewComponent,
        canActivate: [MollieOrderConfirmationGuard],
      },
    ]),
    MollieHttpInterceptorsModule,
  ],
  providers: [provideDefaultConfig(defaultMollieOccConfig)],
})
export class MollieCheckoutModule {}
