import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserIdService } from '@spartacus/core';
import {
  MultiCartSelectors,
  MultiCartService,
  StateWithMultiCart,
} from '@spartacus/cart/base/core';
import { ProcessesLoaderState } from '@spartacus/core/src/state/utils/processes-loader';
import { JagaCart } from '../models/jaga-cart.model';

@Injectable({
  providedIn: 'root',
})
export class JagaMultiCartService extends MultiCartService {
  constructor(
    protected store: Store<StateWithMultiCart>,
    protected userIdService: UserIdService,
  ) {
    super(store, userIdService);
  }

  getCart(cartId: string): Observable<JagaCart> {
    return this.store.pipe(
      select(MultiCartSelectors.getCartSelectorFactory(cartId)),
    );
  }

  getCartEntity(cartId: string): Observable<ProcessesLoaderState<JagaCart>> {
    return this.store.pipe(
      select(MultiCartSelectors.getCartEntitySelectorFactory(cartId)),
    );
  }
}
