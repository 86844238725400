import { NgModule } from '@angular/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import { CartBaseCoreModule } from '@spartacus/cart/base/core';
import { jagaOccCartConfig } from './base/occ/config/jaga-occ-cart.config';
import { provideConfig } from '@spartacus/core';
import { JagaCartComponentsModule } from './base/components/jaga-cart-components.module';

@NgModule({
  declarations: [],
  imports: [JagaCartComponentsModule, CartBaseOccModule, CartBaseCoreModule],
  providers: [provideConfig(jagaOccCartConfig)],
})
export class JagaCartModule {}
