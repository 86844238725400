import { Suggestion } from '@spartacus/core';
import {
  QuickOrderSearchState,
  QuickOrderState,
  StateWithQuickOrder,
} from '../quick-order-state';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as fromQuickOrderSearch from '../reducers/quick-order-search.reducer';
import { getQuickOrderState } from './feature.selector';

export const getQuickOrderSearchState: MemoizedSelector<
  StateWithQuickOrder,
  QuickOrderSearchState
> = createSelector(
  getQuickOrderState,
  (state: QuickOrderState) => state.search
);

export const getQuickOrderSuggestions: MemoizedSelector<
  StateWithQuickOrder,
  Suggestion[]
> = createSelector(
  getQuickOrderSearchState,
  fromQuickOrderSearch.getQuickOrderSuggestions
);
