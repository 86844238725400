import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  CarouselModule,
  CurrentProductService,
  IconConfig,
  MediaModule,
  OutletModule,
} from '@spartacus/storefront';
import { JagaProductListModule } from './jaga-product-list/jaga-product-list.module';
import { jagaOccProductConfig } from './config/jaga-occ-product-config';
import { JagaProductIntroComponent } from './components/jaga-product-intro/jaga-product-intro.component';
import { JagaProductSummaryComponent } from './components/jaga-product-summary/jaga-product-summary.component';
import { JagaCurrentProductService } from './jaga-current-product.service';
import { RouterModule } from '@angular/router';
import { JagaProductCarouselComponent } from './components/carousel/jaga-product-carousel/jaga-product-carousel.component';
import { JagaProductDetailsComponent } from './components/jaga-product-details/jaga-product-details.component';
import { JagaProductManualsComponent } from './components/jaga-product-manuals/jaga-product-manuals.component';
import { JagaProductDimensionsTabComponent } from './components/tabs/jaga-product-dimensions/jaga-product-dimensions-tab.component';
import { JagaProductColorsTabComponent } from './components/tabs/jaga-product-colors-tab/jaga-product-colors-tab.component';
import { JagaProductConnectionTabComponent } from './components/tabs/jaga-product-connection-tab/jaga-product-connection-tab.component';
import { JagaProductDeliveryTabComponent } from './components/tabs/jaga-product-delivery-tab/jaga-product-delivery-tab.component';
import { JagaProductDownloadsTabComponent } from './components/tabs/jaga-product-downloads-tab/jaga-product-downloads-tab.component';
import { JagaProductAttributesTabComponent } from './components/tabs/jaga-product-attributes-tab/jaga-product-attributes-tab.component';
import { JagaCartModule } from '../jaga-cart/jaga-cart.module';
import { JagaProductReferencesComponent } from './components/carousel/jaga-product-references/jaga-product-references.component';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import { JagaProductDeliveryTimeDescriptionComponent } from './components/jaga-product-delivery-time-description/jaga-product-delivery-time-description.component';
import { JagaProductImagesComponent } from './components/jaga-product-images/jaga-product-images.component';
import { JagaProductVariantsModule } from './jaga-product-variants/jaga-product-variants.module';
import { JagaOrderSummaryModule } from '../jaga-cart/jaga-cart-shared/jaga-order-summary/jaga-order-summary.module';
import { JagaAddToCartModule } from '../jaga-cart/base/components/jaga-add-to-cart/jaga-add-to-cart.module';

@NgModule({
  declarations: [
    JagaProductIntroComponent,
    JagaProductSummaryComponent,
    JagaProductCarouselComponent,
    JagaProductDetailsComponent,
    JagaProductManualsComponent,
    JagaProductDimensionsTabComponent,
    JagaProductColorsTabComponent,
    JagaProductConnectionTabComponent,
    JagaProductDeliveryTabComponent,
    JagaProductDownloadsTabComponent,
    JagaProductAttributesTabComponent,
    JagaProductReferencesComponent,
    JagaProductDeliveryTimeDescriptionComponent,
    JagaProductImagesComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      icon: {
        symbols: {
          LIST: 'fas fa-list-ul',
        },
      },
    } as IconConfig),
    ConfigModule.withConfig(jagaOccProductConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        ProductIntroComponent: {
          component: JagaProductIntroComponent,
        },
        ProductSummaryComponent: {
          component: JagaProductSummaryComponent,
        },
        ProductCarouselComponent: {
          component: JagaProductCarouselComponent,
        },
        ProductDetailsComponent: {
          component: JagaProductDetailsComponent,
        },
        ProductManualsComponent: {
          component: JagaProductManualsComponent,
        },
        ProductSpecsTabComponent: {
          component: JagaProductAttributesTabComponent,
        },
        ProductDimensionsTabComponent: {
          component: JagaProductDimensionsTabComponent,
        },
        ProductColorsTabComponent: {
          component: JagaProductColorsTabComponent,
        },
        ProductConnectionTabComponent: {
          component: JagaProductConnectionTabComponent,
        },
        ProductDeliveryTabComponent: {
          component: JagaProductDeliveryTabComponent,
        },
        ProductDownloadsTabComponent: {
          component: JagaProductDownloadsTabComponent,
        },
        ProductReferencesComponent: {
          component: JagaProductReferencesComponent,
        },
        ProductDeliveryTimeDescriptionComponent: {
          component: JagaProductDeliveryTimeDescriptionComponent,
        },
        ProductImagesComponent: {
          component: JagaProductImagesComponent,
        },
      },
    } as CmsConfig),
    JagaProductListModule,
    I18nModule,
    OutletModule,
    RouterModule,
    UrlModule,
    CarouselModule,
    MediaModule,
    JagaAddToCartModule,
    JagaCartModule,
    JagaCommonModule,
    JagaProductVariantsModule,
    JagaOrderSummaryModule,
  ],
  providers: [
    { provide: CurrentProductService, useExisting: JagaCurrentProductService },
  ],
})
export class JagaProductModule {}
