import { Component, Input } from '@angular/core';
import { QuickOrderEntry } from '../../models/quick-order.model';
import { QuickOrderService } from '../../services/quick-order.service';

@Component({
  selector: 'jaga-quick-order-item',
  templateUrl: './jaga-quick-order-item.component.html',
})
export class JagaQuickOrderItemComponent {
  @Input() entry: QuickOrderEntry;
  @Input() isDemo = false;
  step = 1;
  min = 1;
  max = 50; // because of cart entry has maximum limit to 50

  constructor(private quickOrderService: QuickOrderService) {}

  changeQuantity(entry: QuickOrderEntry, $event) {
    this.quickOrderService.changeQuantity(
      entry,
      this.getValidCount($event.target.value)
    );
  }

  removeItem(entry: QuickOrderEntry) {
    this.quickOrderService.removeEntry(entry);
  }

  increment(entry) {
    this.quickOrderService.changeQuantity(
      entry,
      this.getValidCount(entry.quantity + this.step)
    );
  }

  decrement(entry) {
    this.quickOrderService.changeQuantity(
      entry,
      this.getValidCount(entry.quantity - this.step)
    );
  }

  private getValidCount(value: number) {
    if (value < this.min) {
      value = this.min;
    }
    if (this.max && value > this.max) {
      value = this.max;
    }
    return value;
  }
}
