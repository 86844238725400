import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
import { provideDefaultConfig } from '@spartacus/core';
import { jagaB2bOccConfig } from '../../base/occ/config';
import { JagaOccCheckoutPaymentTypeAdapter } from './adapters/occ-checkout-payment-type.adapter';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(jagaB2bOccConfig),
    {
      provide: CheckoutPaymentTypeAdapter,
      useClass: JagaOccCheckoutPaymentTypeAdapter,
    },
  ],
})
export class JagaCheckoutB2BOccModule {}
