import { Component } from '@angular/core';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
  selector: 'jaga-checkout-order-summary',
  templateUrl: './jaga-checkout-order-summary.component.html',
})
export class JagaCheckoutOrderSummaryComponent extends CheckoutOrderSummaryComponent {
  constructor(activeCartService: ActiveCartFacade) {
    super(activeCartService);
  }
}
