import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { JagaCheckoutProgressComponent } from './jaga-checkout-progress.component';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutStepsSetGuard,
} from '@spartacus/checkout/base/components';

@NgModule({
  imports: [CommonModule, UrlModule, I18nModule, RouterModule],
  declarations: [JagaCheckoutProgressComponent],
  exports: [JagaCheckoutProgressComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutProgress: {
          component: JagaCheckoutProgressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CheckoutStepsSetGuard],
        },
      },
    }),
  ],
})
export class JagaCheckoutProgressModule {}
