import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JagaCustomFormValidators } from '@jaga/util';
import {
  GlobalMessageService,
  TranslationService,
  UserAddressService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { AddressFormComponent } from '@spartacus/user/profile/components';

import { UserProfileFacade } from '@spartacus/user/profile/root';

@Component({
  selector: 'jaga-address-form',
  templateUrl: './jaga-address-form.component.html',
})
export class JagaAddressFormComponent
  extends AddressFormComponent
  implements OnInit
{
  firstNameFormName = 'firstName';
  lastNameFormName = 'lastName';
  cityFormName = 'town';
  phoneNumberFormName = 'phone';

  constructor(
    fb: FormBuilder,
    userAddressService: UserAddressService,
    globalMessageService: GlobalMessageService,
    translationService: TranslationService,
    launchDialogService: LaunchDialogService,
    userProfileFacade: UserProfileFacade
  ) {
    super(
      fb,
      userAddressService,
      globalMessageService,
      translationService,
      launchDialogService,
      userProfileFacade
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.addressForm
      .get(this.firstNameFormName)
      .setValidators([
        Validators.required,
        JagaCustomFormValidators.validateString,
      ]);
    this.addressForm
      .get(this.lastNameFormName)
      .setValidators([
        Validators.required,
        JagaCustomFormValidators.validateString,
      ]);
    this.addressForm
      .get(this.cityFormName)
      .setValidators([
        Validators.required,
        JagaCustomFormValidators.validateString,
      ]);
    this.addressForm
      .get(this.phoneNumberFormName)
      .setValidators(JagaCustomFormValidators.validatePhoneNumber);
  }
}
