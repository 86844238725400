import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { JagaOrderDetailShippingComponent } from './jaga-order-detail-shipping/jaga-order-detail-shipping.component';
import {
  CardModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { JagaOrderDetailItemsComponent } from './jaga-order-detail-items/jaga-order-detail-items.component';
import { JagaCartItemListComponent, JagaOrderSummaryModule } from '@jaga/cart';
import { JagaOrderDetailTotalsComponent } from './jaga-order-detail-totals/jaga-order-detail-totals.component';
import {
  OrderDetailsModule,
  OrderDetailsService,
} from '@spartacus/order/components';
import { JagaOrderDetailHeadlineComponent } from './jaga-order-detail-headline/jaga-order-detail-headline.component';

@NgModule({
  imports: [
    CommonModule,
    I18nModule,
    UrlModule,
    CardModule,
    SpinnerModule,
    OrderDetailsModule,
    JagaOrderSummaryModule,
    PromotionsModule,
    JagaCartItemListComponent,
  ],
  providers: [
    OrderDetailsService,
    provideConfig(<CmsConfig>{
      cmsComponents: {
        AccountOrderDetailsHeadlineComponent: {
          component: JagaOrderDetailHeadlineComponent,
        },
        AccountOrderDetailsShippingComponent: {
          component: JagaOrderDetailShippingComponent,
        },
        AccountOrderDetailsItemsComponent: {
          component: JagaOrderDetailItemsComponent,
        },
        AccountOrderDetailsTotalsComponent: {
          component: JagaOrderDetailTotalsComponent,
        },
      },
    }),
  ],
  declarations: [
    JagaOrderDetailShippingComponent,
    JagaOrderDetailItemsComponent,
    JagaOrderDetailTotalsComponent,
    JagaOrderDetailHeadlineComponent,
  ],
  exports: [JagaOrderDetailShippingComponent],
})
export class JagaOrderDetailsModule {}
