<ng-container *ngIf="orders$ | async as orders">
  <!-- HEADER -->
  <div class="cx-order-history-header">
    <h1>{{ "orderHistory.orderHistory" | cxTranslate }}</h1>
  </div>

  <!-- BODY -->
  <div class="cx-order-history-body">
    <ng-container *ngIf="orders.pagination.totalResults > 0; else noOrder">
      <!-- Select Form and Pagination Top -->
      <div class="cx-order-history-sort top row">
        <div
          class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4"
        >
          <cx-sorting
            (sortListEvent)="changeSortCode($event)"
            [selectedOption]="orders.pagination.sort"
            [sortLabels]="getSortLabels() | async"
            [sortOptions]="orders.sorts"
            placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}"
          ></cx-sorting>
        </div>
        <div class="cx-order-history-pagination">
          <cx-pagination
            (viewPageEvent)="pageChange($event)"
            [pagination]="orders.pagination"
          ></cx-pagination>
        </div>
      </div>
      <!-- TABLE -->
      <table class="table cx-order-history-table">
        <thead class="cx-order-history-thead-mobile">
          <tr>
            <th scope="col">
              {{ "orderHistory.orderId" | cxTranslate }}
            </th>
            <th scope="col">{{ "orderHistory.date" | cxTranslate }}</th>
            <th scope="col">
              {{ "orderHistory.status" | cxTranslate }}
            </th>
            <th scope="col">{{ "orderHistory.total" | cxTranslate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            (click)="goToOrderDetail(order)"
            *ngFor="let order of orders.orders"
          >
            <td class="cx-order-history-code">
              <div class="d-md-none cx-order-history-label">
                {{ "orderHistory.orderId" | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  { cxRoute: 'orderDetails', params: order } | cxUrl
                "
                class="cx-order-history-value"
              >
                {{ order?.code }}
              </a>
            </td>
            <td class="cx-order-history-placed">
              <div class="d-md-none cx-order-history-label">
                {{ "orderHistory.date" | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  { cxRoute: 'orderDetails', params: order } | cxUrl
                "
                class="cx-order-history-value"
              >
                {{ order?.placed | cxDate : "longDate" }}
              </a>
            </td>
            <td class="cx-order-history-status">
              <div class="d-md-none cx-order-history-label">
                {{ "orderHistory.status" | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  { cxRoute: 'orderDetails', params: order } | cxUrl
                "
                class="cx-order-history-value"
              >
                {{
                  "orderDetails.statusDisplay"
                    | cxTranslate : { context: order?.statusDisplay }
                }}
              </a>
            </td>
            <td class="cx-order-history-total">
              <div class="d-md-none cx-order-history-label">
                {{ "orderHistory.total" | cxTranslate }}
              </div>
              <a
                [routerLink]="
                  { cxRoute: 'orderDetails', params: order } | cxUrl
                "
                class="cx-order-history-value"
              >
                {{ order?.total.formattedValue }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- Select Form and Pagination Bottom -->
      <div class="cx-order-history-sort bottom row">
        <div
          class="cx-order-history-form-group form-group col-sm-12 col-md-4 col-lg-4"
        >
          <cx-sorting
            (sortListEvent)="changeSortCode($event)"
            [selectedOption]="orders.pagination.sort"
            [sortLabels]="getSortLabels() | async"
            [sortOptions]="orders.sorts"
            placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}"
          >
          </cx-sorting>
        </div>
        <div class="cx-order-history-pagination">
          <cx-pagination
            (viewPageEvent)="pageChange($event)"
            [pagination]="orders.pagination"
          >
          </cx-pagination>
        </div>
      </div>
    </ng-container>

    <!-- NO ORDER CONTAINER -->
    <ng-template #noOrder>
      <div *ngIf="isLoaded$ | async" class="cx-order-history-no-order row">
        <div class="col-sm-12 col-md-6">
          <div class="text-center text-md-left">
            {{ "orderHistory.noOrders" | cxTranslate }}
          </div>
          <a
            [routerLink]="{ cxRoute: 'home' } | cxUrl"
            class="btn btn-block btn-cta"
          >
            {{ "orderHistory.startShopping" | cxTranslate }}
          </a>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
