import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaOrderDetailsModule } from './jaga-order-details/jaga-order-details.module';
import { JagaOrderHistoryComponent } from './jaga-order-history/jaga-order-history.component';
import {
  AuthGuard,
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ListNavigationModule } from '@spartacus/storefront';
import { JagaOrderSummaryModule } from '../../../jaga-cart/jaga-cart-shared/jaga-order-summary/jaga-order-summary.module';

@NgModule({
  declarations: [JagaOrderHistoryComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderHistoryComponent: {
          component: JagaOrderHistoryComponent,
          guards: [AuthGuard],
        },
      },
    } as CmsConfig),
    JagaOrderDetailsModule,
    RouterModule,
    I18nModule,
    UrlModule,
    ListNavigationModule,
    JagaOrderSummaryModule,
  ],
})
export class JagaOrderModule {}
