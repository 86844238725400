import {
  ConverterService,
  InterceptorUtil,
  OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MollieOccPlaceOrderResponse } from '../models/mollie-occ.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OccMollieCheckoutAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  placeOrder(userId: string, cartId: string, termsChecked: boolean) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    const params = new HttpParams()
      .set('cartId', cartId)
      .set('termsChecked', termsChecked.toString());

    return this.http
      .post<MollieOccPlaceOrderResponse>(
        this.occEndpoints.buildUrl('molliePlaceOrder', {
          urlParams: { userId },
        }),
        params,
        { headers }
      )
      .pipe(map((response) => response.postUrl));
  }

  protected getPlaceOrderEndpoint(
    userId: string,
    cartId: string,
    termsChecked: string
  ): string {
    return this.occEndpoints.buildUrl('placeOrder', {
      urlParams: { userId },
      queryParams: { cartId, termsChecked },
    });
  }
}
