import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaOrderSummaryComponent } from './jaga-order-summary.component';
import { I18nModule } from '@spartacus/core';
import { CartCouponModule } from '@spartacus/cart/base/components';

@NgModule({
  imports: [CommonModule, I18nModule, CartCouponModule],
  declarations: [JagaOrderSummaryComponent],
  exports: [JagaOrderSummaryComponent],
})
export class JagaOrderSummaryModule {}
