import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { RouterModule } from '@angular/router';
import { ItemCounterModule } from '@spartacus/storefront';
import { JagaAddToCartComponent } from './jaga-add-to-cart.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [JagaAddToCartComponent],
  exports: [JagaAddToCartComponent],
  imports: [
    CommonModule,
    I18nModule,
    ItemCounterModule,
    UrlModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        ProductAddToCartComponent: {
          component: JagaAddToCartComponent,
        },
      },
    }),
  ],
})
export class JagaAddToCartModule {}
