import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserIdService, WindowRef } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { JagaMultiCartService } from './jaga-multi-cart.service';
import { JagaCart } from '../models/jaga-cart.model';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Injectable({
  providedIn: 'root',
})
export class JagaActiveCartService
  extends ActiveCartService
  implements ActiveCartFacade, OnDestroy
{
  protected subscription = new Subscription();
  protected activeCart$: Observable<JagaCart>;

  constructor(
    protected multiCartService: JagaMultiCartService,
    protected userIdService: UserIdService,
    // TODO:Spartacus - The type of property 'winRef: WindowRef | undefined' changed to: 'winRef: WindowRef'
    protected winRef: WindowRef,
  ) {
    // TODO:Spartacus - The type of property 'winRef: WindowRef | undefined' changed to: 'winRef: WindowRef'
    super(multiCartService, userIdService, winRef);
  }

  getActive(): Observable<JagaCart> {
    return this.activeCart$;
  }
}
