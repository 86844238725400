import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { CMSQuickOrderComponent } from '../../models/jaga-cms.model';
import { Observable } from 'rxjs';
import { QuickOrderService } from '../../services/quick-order.service';
import { QuickOrderEntry } from '../../models/quick-order.model';
import { Price, Product } from '@spartacus/core';

@Component({
  selector: 'jaga-quick-order',
  templateUrl: './jaga-quick-order-list.component.html',
})
export class JagaQuickOrderListComponent {
  entries$: Observable<any>;
  demoEntry = {
    product: {
      code: 'SKU Code',
      groupDescription: '',
      name: 'Product name',
    } as Product,
    quantity: 1,
    basePrice: {
      formattedValue: '€ 00,0',
    } as Price,
  } as QuickOrderEntry;

  constructor(
    public component: CmsComponentData<CMSQuickOrderComponent>,
    private quickOrderService: QuickOrderService
  ) {
    this.entries$ = this.quickOrderService.getEntries();
  }

  clearEntries() {
    this.quickOrderService.clearEntries();
  }

  addEntriesToCart(minRows: number, entries: QuickOrderEntry[]) {
    this.quickOrderService.addToCart(minRows, entries);
  }
}
