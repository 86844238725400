import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';
import { CartTotalsComponent } from '@spartacus/cart/base/components';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
  selector: 'jaga-cart-totals',
  templateUrl: './jaga-cart-totals.component.html',
  styles: [
    `
      :host {
        padding: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaCartTotalsComponent extends CartTotalsComponent {
  constructor(activeCartService: ActiveCartFacade, private location: Location) {
    super(activeCartService);
  }

  backClicked() {
    this.location.back();
  }
}
