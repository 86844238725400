import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { QuickOrderSearchConfig } from '../../models/quick-order-search.config';
import { QuickOrderSearchService } from '../../services/quick-order-search.service';
import { map, tap } from 'rxjs/operators';
import { WindowRef } from '@spartacus/core';
import { QuickOrderService } from '../../services/quick-order.service';
import { ICON_TYPE } from '@spartacus/storefront';

export const DEFAULT_SEARCHBOX_CONFIG: QuickOrderSearchConfig = {
  minCharactersBeforeRequest: 1,
  maxSuggestions: 5,
};

@Component({
  selector: 'jaga-quick-order-search',
  templateUrl: './jaga-quick-order-search.component.html',
})
export class JagaQuickOrderSearchComponent {
  @Input()
  disabled = false;

  @Input()
  maxSuggestions: number;

  showResults = false;

  @ViewChild('searchInput')
  searchInput: ElementRef<HTMLInputElement>;

  IconType = ICON_TYPE;

  results$: Observable<string[]> = this.quickOrderSearchService
    .getSuggestionResults()
    .pipe(
      tap((results) => (this.showResults = results.length > 0)),
      map((results) => results.map((result) => result.toUpperCase()))
    );

  constructor(
    private quickOrderSearchService: QuickOrderSearchService,
    private quickOrderService: QuickOrderService,
    private winRef: WindowRef,
    private changeDetector: ChangeDetectorRef
  ) {}

  search(query: string) {
    if (query.length === 0) {
      this.showResults = false;
      this.quickOrderSearchService.clearResults();
    }

    const config = DEFAULT_SEARCHBOX_CONFIG;
    if (this.maxSuggestions > 0) {
      config.maxSuggestions = this.maxSuggestions;
    }

    this.quickOrderSearchService.search(query, config);
  }

  open() {
    this.showResults = true;
  }

  close($event: FocusEvent, force?: boolean) {
    $event.preventDefault();
    setTimeout(() => {
      if (!this.preventClose() || force) {
        this.showResults = false;
        this.changeDetector.detectChanges();
      }
    });
  }

  focusPreviousChild(event) {
    event.preventDefault();
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];

    if (results.length) {
      if (focusedIndex < 1) {
        results[results.length - 1].focus();
      } else {
        results[focusedIndex - 1].focus();
      }
    }
  }

  // Focus on next item in results list
  focusNextChild(event) {
    event.preventDefault();
    const [results, focusedIndex] = [
      this.getResultElements(),
      this.getFocusedIndex(),
    ];
    if (results.length) {
      results[(focusedIndex + 1) % results.length].focus();
    }
  }

  addEntry($event, sku) {
    this.close($event, true);
    this.quickOrderSearchService.clearResults();

    this.quickOrderService.addEntry(sku);
    this.searchInput.nativeElement.value = '';
    this.searchInput.nativeElement.focus();
  }

  private getResultElements(): HTMLElement[] {
    return Array.from(
      this.winRef.document.querySelectorAll(
        'jaga-quick-order-search .suggestions a'
      )
    );
  }

  private getFocusedElement(): HTMLElement {
    return this.winRef.document.activeElement as HTMLElement;
  }

  private getFocusedIndex(): number {
    return this.getResultElements().indexOf(this.getFocusedElement());
  }

  private preventClose(): boolean {
    return (
      this.getResultElements().includes(this.getFocusedElement()) ||
      this.searchInput.nativeElement === this.getFocusedElement()
    );
  }
}
