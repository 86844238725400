import { Injectable } from '@angular/core';
import {
  Command,
  CommandService,
  CommandStrategy,
  OCC_USER_ID_ANONYMOUS,
  UserIdService,
  WindowRef,
} from '@spartacus/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { OccMollieCheckoutAdapter } from '../occ/checkout/occ-mollie-checkout.adapter';

@Injectable({
  providedIn: 'root',
})
export class MollieOrderService {
  constructor(
    protected activeCartFacade: ActiveCartFacade,
    protected userIdService: UserIdService,
    protected commandService: CommandService,
    protected orderConnector: OccMollieCheckoutAdapter,
    private winRef: WindowRef
  ) {}

  protected placeOrderCommand: Command<boolean, string> =
    this.commandService.create<boolean, string>(
      (payload) =>
        this.checkoutPreconditions().pipe(
          switchMap(([userId, cartId]) =>
            this.orderConnector.placeOrder(userId, cartId, payload).pipe(
              tap((order) => {
                const nativeWindow = this.winRef.nativeWindow;

                if (nativeWindow) {
                  nativeWindow.location.href = order as string;
                }
              })
            )
          )
        ),
      {
        strategy: CommandStrategy.CancelPrevious,
      }
    );

  placeOrder(termsChecked: boolean): Observable<string> {
    return this.placeOrderCommand.execute(termsChecked);
  }

  protected checkoutPreconditions(): Observable<[string, string]> {
    return combineLatest([
      this.userIdService.takeUserId(),
      this.activeCartFacade.takeActiveCartId(),
      this.activeCartFacade.isGuestCart(),
    ]).pipe(
      take(1),
      map(([userId, cartId, isGuestCart]) => {
        if (
          !userId ||
          !cartId ||
          (userId === OCC_USER_ID_ANONYMOUS && !isGuestCart)
        ) {
          throw new Error('Checkout conditions not met');
        }
        return [userId, cartId];
      })
    );
  }

  // TODO: Get order details error
  // getOrderDetailsErrors(): Observable<boolean> {
  //   return this.orderStateStore.pipe(
  //     select(MollieSelectors.getOrderDetailsError)
  //   );
  // }
}
