<section *ngIf="(activeStepIndex$ | async) !== undefined">
  <div class="cx-nav d-none d-lg-block d-xl-block">
    <ul class="cx-list">
      <ng-container *ngFor="let step of steps$ | async; let i = index">
        <li
          class="cx-item"
          [class.active]="isActive(i)"
          [class.disabled]="isDisabled(i)"
        >
          <a
            [routerLink]="{ cxRoute: step.routeName } | cxUrl"
            class="cx-link"
            [class.active]="isActive(i)"
            [class.disabled]="isDisabled(i)"
            [tabindex]="getTabIndex(i)"
          >
            {{ i + 1 }}. {{ step.name | cxTranslate }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</section>
