import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { JagaCmsLinkComponent } from '@jaga/common';

@Component({
  selector: 'jaga-link',
  templateUrl: './jaga-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JagaLinkComponent {
  constructor(public component: CmsComponentData<JagaCmsLinkComponent>) {}

  /**
   * Returns `_blank` to force opening the link in a new window whenever the
   * `data.target` flag is set to `true`.
   */
  getTarget(data: CmsLinkComponent): string | null {
    return data.target === 'true' || data.target === true ? '_blank' : null;
  }
}
