<div class="cx-review">
  <!-- TITLE -->
  <h5 class="h5 d-none d-lg-block">
    {{ "checkoutReview.review" | cxTranslate }}
  </h5>

  <!-- CART ITEM SECTION -->
  <ng-container *ngIf="cart$ | async as cart">
    <h5 class="h5 d-block d-lg-none mt-3">
      {{ "checkoutReview.placeOrder" | cxTranslate }}
    </h5>
    <div
      *ngIf="entries$ | async as entries"
      class="cx-review-cart-item col-md-12 mb-4 px-0"
    >
      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <cx-promotions
          *ngIf="orderPromotions.length"
          [promotions]="orderPromotions"
        ></cx-promotions>
      </ng-container>

      <jaga-cart-item-list
        [items]="entries"
        [promotionLocation]="promotionLocation"
        [readonly]="true"
      >
      </jaga-cart-item-list>
    </div>
  </ng-container>

  <!-- ORDER DETAILS -->
  <button
    (click)="showDetails = !showDetails"
    [class.active]="showDetails"
    [innerHTML]="'Order details'"
    class="toggleDetails"
  ></button>

  <div *ngIf="showDetails" class="cx-review-summary row">
    <!-- PO NUMBER SECTION -->
    <div class="col-md-12 col-lg-4 cx-review-payment-col">
      <div class="cx-review-summary-card">
        <cx-card
          [content]="getPoNumberCard(poNumber$ | async) | async"
        ></cx-card>
        <div class="cx-review-summary-edit-step">
          <a [routerLink]="{ cxRoute: 'checkoutPaymentType' } | cxUrl">
            {{ "checkoutReview.editPoNumber" | cxTranslate }}
          </a>
        </div>
      </div>
    </div>

    <!-- PAYMENT TYPE SECTION -->
    <div class="col-md-12 col-lg-4 cx-review-payment-col">
      <div
        *ngIf="paymentType$ | async as paymentType"
        class="cx-review-summary-card"
      >
        <cx-card [content]="getPaymentTypeCard(paymentType) | async"></cx-card>
        <div class="cx-review-summary-edit-step">
          <a [routerLink]="{ cxRoute: 'checkoutPaymentType' } | cxUrl">
            {{ "checkoutReview.editPaymentType" | cxTranslate }}
          </a>
        </div>
      </div>
    </div>

    <!-- REMARKS SECTION -->
    <div class="col-md-12 col-lg-4 cx-review-payment-col">
      <div class="cx-review-summary-card">
        <cx-card [content]="getRemarksCard(remarks) | async"></cx-card>
        <div class="cx-review-summary-edit-step">
          <a
            [routerLink]="
              { cxRoute: getCheckoutStepUrl(checkoutDeliveryMode) } | cxUrl
            "
          >
            {{ "checkoutReview.editRemarks" | cxTranslate }}
          </a>
        </div>
      </div>
    </div>

    <!-- BILLING ADDRESS SECTION -->
    <div class="col-md-12 col-lg-4 jaga-review-shipping-col">
      <div class="cx-review-summary-card cx-review-card-address">
        <cx-card
          [content]="
            getBillingAddressCard(billingAddress$ | async, countryName$ | async)
              | async
          "
        >
        </cx-card>
      </div>
    </div>

    <!-- SHIPPING ADDRESS SECTION -->
    <div class="col-md-12 col-lg-4 jaga-review-shipping-col">
      <div class="cx-review-summary-wrapper">
        <div
          *ngIf="deliveryAddress$ | async as deliveryAddress"
          class="cx-review-summary-card cx-review-card-address"
        >
          <cx-card
            [content]="
              getShippingAddressCard(deliveryAddress, countryName$ | async)
                | async
            "
          >
          </cx-card>
        </div>
        <div class="cx-review-summary-edit-step">
          <a
            [routerLink]="
              { cxRoute: getCheckoutStepUrl(checkoutDeliveryAddress) } | cxUrl
            "
          >
            {{ "checkoutReview.editShippingAddress" | cxTranslate }}
          </a>
        </div>
      </div>
    </div>

    <!-- DELIVERY MODE SECTION -->
    <div class="col-md-12 col-lg-4 jaga-review-shipping-col">
      <div class="cx-review-summary-wrapper">
        <div class="cx-review-summary-card cx-review-card-shipping">
          <cx-card
            *ngIf="deliveryMode$ | async as deliveryMode"
            [content]="getDeliveryModeCard(deliveryMode) | async"
          ></cx-card>
        </div>
        <div class="cx-review-summary-edit-step">
          <a
            [routerLink]="
              { cxRoute: getCheckoutStepUrl(checkoutDeliveryMode) } | cxUrl
            "
          >
            {{ "checkoutReview.editShippingMethod" | cxTranslate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
