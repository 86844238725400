import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaWebsiteSelectorComponent } from './jaga-website-selector.component';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [JagaWebsiteSelectorComponent],
  imports: [IconModule, CommonModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        JagaWebsiteSelectorComponent: {
          component: JagaWebsiteSelectorComponent,
        },
      },
    }),
  ],
})
export class JagaWebsiteSelectorModule {}
