import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { JagaMiniCartComponent } from './jaga-mini-cart.component';
import { RouterModule } from '@angular/router';
import { IconModule } from '@spartacus/storefront';

@NgModule({
  declarations: [JagaMiniCartComponent],
  imports: [CommonModule, I18nModule, IconModule, UrlModule, RouterModule],
  exports: [JagaMiniCartComponent],
  providers: [
    provideConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: JagaMiniCartComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class JagaMiniCartModule {}
