import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { JagaCartCouponComponent } from './jaga-cart-coupon.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CartCouponModule } from '@spartacus/cart/base/components';

@NgModule({
  declarations: [JagaCartCouponComponent],
  imports: [CommonModule, I18nModule, CartCouponModule, ReactiveFormsModule],
  providers: [
    provideConfig({
      cmsComponents: {
        CartApplyCouponComponent: {
          component: JagaCartCouponComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class JagaCartCouponModule {}
