import { Component } from '@angular/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import {
  CmsBannerComponent,
  CmsService,
  SemanticPathService,
} from '@spartacus/core';

@Component({
  selector: 'jaga-banner',
  templateUrl: './jaga-banner.component.html',
})
export class JagaBannerComponent extends BannerComponent {
  constructor(
    public component: CmsComponentData<CmsBannerComponent>,
    urlService: SemanticPathService,
    cmsService: CmsService
  ) {
    super(component, urlService, cmsService);
  }

  /**
   * Returns `_blank` to force opening the link in a new window whenever the
   * `data.target` flag is set to `true`.
   */
  getTarget(data: any): string | null {
    return data.target === 'true' || data.target === true ? '_blank' : null;
  }
}
