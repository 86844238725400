import { NgModule } from '@angular/core';
import { SiteContextConfigInitializer } from '@spartacus/core';
import { JagaSiteContextConfigInitializer } from './config-loader/jaga-site-context-config-initializer.config';

@NgModule({
  providers: [
    {
      provide: SiteContextConfigInitializer,
      useExisting: JagaSiteContextConfigInitializer,
    },
  ],
})
export class JagaOccModule {}
