<form
  (ngSubmit)="submitPlaceOrderForm()"
  [formGroup]="checkoutSubmitForm"
  class="cx-place-order-form form-check"
>
  <div class="form-group">
    <label>
      <input
        class="form-check-input"
        formControlName="termsAndConditions"
        type="checkbox"
      />
      <span class="form-check-label">
        {{ "checkoutReview.confirmThatRead" | cxTranslate }}
        <a
          [routerLink]="{ cxRoute: 'termsAndConditions' } | cxUrl"
          class="cx-tc-link"
          target="_blank"
        >
          {{ "checkoutReview.termsAndConditions" | cxTranslate }}
        </a>
      </span>
      <cx-form-errors [control]="checkoutSubmitForm.get('termsAndConditions')">
      </cx-form-errors>
    </label>
  </div>

  <button class="btn btn-primary btn-block" type="submit">
    {{ "checkoutReview.placeOrder" | cxTranslate }}
  </button>
</form>
