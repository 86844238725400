import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  PageLayoutService,
  ProductListComponentService,
  ViewConfig,
  ViewModes,
} from '@spartacus/storefront';
import { take, tap, skip } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ProductSearchPage, RoutingService } from '@spartacus/core';

@Component({
  selector: 'jaga-product-list',
  templateUrl: './jaga-product-list.component.html',
})
export class JagaProductListComponent implements OnInit, OnDestroy {
  isInfiniteScroll: boolean;

  model$: Observable<ProductSearchPage>;

  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);
  ViewModes = ViewModes;
  private subscriptions: Subscription[] = [];

  constructor(
    private pageLayoutService: PageLayoutService,
    private productListComponentService: ProductListComponentService,
    public scrollConfig: ViewConfig,
    protected routingService: RoutingService,
  ) {}

  ngOnInit() {
    this.model$ = this.productListComponentService.model$;
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;

    this.subscriptions.push(
      this.model$
        .pipe(
          skip(1),
          tap((searchPage) => {
            if (!searchPage.products?.length) {
              this.routingService.go({ cxRoute: 'searchEmpty' });
            }
          }),
        )
        .subscribe(),
    );

    this.subscriptions.push(
      this.pageLayoutService.templateName$
        .pipe(take(1))
        .subscribe((template) => {
          this.viewMode$.next(
            template === 'ProductGridPageTemplate' ||
              template === 'SearchResultsGridPageTemplate'
              ? ViewModes.Grid
              : ViewModes.List,
          );
        }),
    );
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  setViewMode(mode: ViewModes): void {
    this.viewMode$.next(mode);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  doPaginationYourself($event: number) {}
}
