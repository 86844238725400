import { Observable } from 'rxjs';
import { JagaCart } from '../../../../../jaga-cart/base/core/models/jaga-cart.model';

export abstract class JagaRemarksAdapter {
  abstract getRemarks(): Observable<string>;

  /**
   * Abstract method used to set remarks on cart
   */
  abstract setRemarks(
    userId: string,
    cartId: string,
    remarks: string,
  ): Observable<JagaCart>;
}
