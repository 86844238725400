import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { OCC_USER_ID_ANONYMOUS, UserIdService } from '@spartacus/core';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { JagaActiveCartService } from '@jaga/cart';
import { JagaProduct } from '@jaga/product';
import { JagaOccCheckoutDeliveryDateAdapter } from '../../occ/adapters/jaga-occ-checkout-delivery-date-adapter.service';

@Injectable({
  providedIn: 'root',
})
export class JagaDeliveryDateService {
  deliveryDate = new BehaviorSubject(undefined);

  constructor(
    protected activeCartService: JagaActiveCartService,
    protected userIdService: UserIdService,
    protected deliveryDateAdapter: JagaOccCheckoutDeliveryDateAdapter,
  ) {}

  getMaxDeliveryDays(): Observable<number> {
    return this.activeCartService.getEntries().pipe(
      map((data) => {
        const status = data.reduce((item, curr) => {
          const itemProduct = item.product as JagaProduct;
          const currProduct = curr.product as JagaProduct;
          return itemProduct.maxDeliveryDays > currProduct.maxDeliveryDays
            ? item
            : curr;
        });
        return (status.product as JagaProduct).maxDeliveryDays;
      }),
    );
  }

  getDeliveryDate(): Observable<string> {
    return this.activeCartService.getActive().pipe(
      take(1),
      map((cart) => cart.deliveryDate ?? ''),
    );
  }

  setDeliveryDate(deliveryDate: string) {
    return combineLatest([
      this.activeCartService.getActiveCartId(),
      this.userIdService.getUserId(),
    ]).pipe(
      take(1),
      switchMap(([cartId, userId]) => {
        if (userId && userId !== OCC_USER_ID_ANONYMOUS && cartId) {
          return this.deliveryDateAdapter.setDeliveryDate(
            userId,
            cartId,
            deliveryDate,
          );
        }
      }),
      tap(() => {
        this.deliveryDate.next(deliveryDate);
      }),
    );
  }
}
