import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { JagaDeliveryDateService } from '../../core/facade/jaga-delivery-date.service';
import {
  NgbCalendar,
  NgbCalendarGregorian,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { BaseSiteService } from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  CheckoutConfigService,
  CheckoutDeliveryModeComponent,
  CheckoutStepService,
} from '@spartacus/checkout/base/components';
import { CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { JagaRemarksService } from '../../core/facade/jaga-remarks.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { JagaBaseSite } from '@jaga/website';

@Component({
  selector: 'jaga-delivery-mode',
  templateUrl: './jaga-delivery-mode.component.html',
  styleUrls: ['./jaga-delivery-mode.component.scss'],
})
export class JagaDeliveryModeComponent
  extends CheckoutDeliveryModeComponent
  implements OnInit
{
  readonly remarksFormName: string = 'remarks';
  readonly deliveryDateFormName: string = 'deliveryDate';
  private datePipe: DatePipe;
  private calendar: NgbCalendar;

  constructor(
    fb: UntypedFormBuilder,
    checkoutConfigService: CheckoutConfigService,
    activatedRoute: ActivatedRoute,
    checkoutStepService: CheckoutStepService,
    checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected activeCartFacade: ActiveCartFacade,
    datePipe: DatePipe,
    private remarksService: JagaRemarksService,
    private deliveryDateService: JagaDeliveryDateService,
    private baseSiteService: BaseSiteService,
  ) {
    super(
      fb,
      checkoutConfigService,
      activatedRoute,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      activeCartFacade,
    );
    this.datePipe = datePipe;
    this.calendar = new NgbCalendarGregorian();
  }

  get remarksFormControl(): FormControl {
    return this.mode.get(this.remarksFormName) as FormControl;
  }

  get deliveryDateFormControl(): FormControl {
    return this.mode.get(this.deliveryDateFormName) as FormControl;
  }

  get remarks(): string {
    return this.remarksFormControl.value;
  }

  get deliveryDate(): NgbDate {
    return this.deliveryDateFormControl.value;
  }

  get enableDatePickerCheckout() {
    return this.baseSiteService
      .get()
      .pipe(map((baseSite: JagaBaseSite) => baseSite.enableDatePickerCheckout));
  }

  get minDate$(): Observable<NgbDate> {
    return this.deliveryDateService.getMaxDeliveryDays().pipe(
      map((addedDeliveryDates: number) => {
        return this.calendar.getNext(
          this.calendar.getToday(),
          'd',
          addedDeliveryDates,
        );
      }),
      distinctUntilChanged(),
    );
  }

  isDisabled(date: NgbDate): boolean {
    const calendar = new NgbCalendarGregorian();
    const minDate = calendar.getNext(calendar.getToday(), 'd', 3);
    const weekday = calendar.getWeekday(date);
    return date.before(minDate) || weekday === 6 || weekday === 7;
  }

  saveDeliveryDate() {
    const date = new Date(
      this.deliveryDate.year,
      this.deliveryDate.month - 1,
      this.deliveryDate.day,
    );
    this.deliveryDateService
      .setDeliveryDate(this.datePipe.transform(date, 'yyyy-MM-ddThh:mm:ssZ'))
      .subscribe();
  }

  ngOnInit() {
    this.supportedDeliveryModes$ = this.checkoutDeliveryModesFacade
      .getSupportedDeliveryModes()
      .pipe(
        filter((deliveryModes) => !!deliveryModes?.length),
        distinctUntilChanged((current, previous) => {
          return JSON.stringify(current) === JSON.stringify(previous);
        }),
      );

    this.selectedDeliveryModeCode$ = this.checkoutDeliveryModesFacade
      .getSelectedDeliveryModeState()
      .pipe(
        filter((state) => !state.loading),
        map((state) => state.data),
        map((deliveryMode) => deliveryMode?.code),
      );

    this.mode.addControl(this.remarksFormName, new FormControl());
    this.mode.addControl(
      this.deliveryDateFormName,
      new FormControl(Validators.required),
    );

    const remarks = this.remarksService.getRemarks();
    this.remarksFormControl.setValue(remarks);

    this.deliveryDateService
      .getDeliveryDate()
      .pipe(
        take(1),
        tap((deliveryDate: string) => {
          const date = new Date(deliveryDate);
          this.deliveryDateFormControl.setValue(
            new NgbDate(
              date.getUTCFullYear(),
              date.getUTCMonth() + 1,
              date.getDate(),
            ),
          );
        }),
      )
      .subscribe();
  }

  next() {
    this.baseSiteService
      .get()
      .pipe(
        take(1),
        tap((baseSite: JagaBaseSite) => {
          if (baseSite.enableDatePickerCheckout) {
            if (!this.deliveryDate.day) {
              this.deliveryDateFormControl.markAsTouched();
              return;
            }
          }
        }),
      )
      .subscribe();

    this.remarksService.setRemarks(this.remarks);
    super.next();
  }
}
