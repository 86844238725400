<ng-container *ngIf="cards$ | async as cards">
  <h5 class="h5 d-none d-lg-block">
    {{ "checkoutAddress.shippingAddress" | cxTranslate }}
  </h5>
  <ng-container *ngIf="!(isUpdating$ | async); else loading">
    <ng-container
      *ngIf="cards?.length && !addressFormOpened; else newAddressForm"
    >
      <p class="cx-checkout-text">
        {{ "checkoutAddress.selectYourShippingAddress" | cxTranslate }}
      </p>
      <div class="cx-checkout-body row">
        <div
          *ngFor="let card of cards; let i = index"
          class="cx-shipping-address-card col-12 col-md-6"
        >
          <div
            (click)="selectAddress(card.address)"
            class="cx-shipping-address-card-inner"
          >
            <jaga-address-card
              (sendCard)="selectAddress(card.address)"
              [border]="true"
              [content]="card.card"
              [fitToContainer]="true"
            >
            </jaga-address-card>
          </div>
        </div>

        <div class="jaga-add-address-link col-12">
          <a (click)="showNewAddressForm()" class="btn btn-link">
            {{ "checkoutAddress.addNewAddress" | cxTranslate }}
          </a>
        </div>
      </div>

      <div class="cx-checkout-btns row">
        <div class="col-12 col-md-6 my-2 my-lg-0">
          <button (click)="back()" class="cx-btn btn btn-block btn-action">
            {{ "common.back" | cxTranslate }}
          </button>
        </div>
        <div class="col-12 col-md-6">
          <button
            (click)="next()"
            [disabled]="!(selectedAddress$ | async)?.id"
            class="cx-btn btn btn-block btn-primary"
          >
            {{ "common.continue" | cxTranslate }}
          </button>
        </div>
      </div>
    </ng-container>

    <ng-template #newAddressForm>
      <ng-container *ngIf="cards.length; else initialAddressForm">
        <jaga-address-form
          (backToAddress)="hideNewAddressForm(false)"
          (submitAddress)="addAddress($event)"
          [setAsDefaultField]="false"
          [showTitleCode]="true"
        ></jaga-address-form>
      </ng-container>
      <ng-template #initialAddressForm>
        <jaga-address-form
          (backToAddress)="hideNewAddressForm(true)"
          (submitAddress)="addAddress($event)"
          [addressData]="selectedAddress$ | async"
          [setAsDefaultField]="false"
          [showTitleCode]="true"
          cancelBtnLabel="{{ 'common.back' | cxTranslate }}"
        >
        </jaga-address-form>
      </ng-template>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <div class="cx-spinner">
      <cx-spinner></cx-spinner>
    </div>
  </ng-template>
</ng-container>
