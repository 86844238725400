import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { QuickOrderSearchConfig } from '../models/quick-order-search.config';
import { map } from 'rxjs/operators';
import { StateWithQuickOrder } from '../store/quick-order-state';
import { QuickOrderSelectors } from '../store/selectors';
import { QuickOrderActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class QuickOrderSearchService {
  constructor(private store: Store<StateWithQuickOrder>) {}

  search(query: string, config?: QuickOrderSearchConfig): void {
    if (
      config.minCharactersBeforeRequest &&
      query.length < config.minCharactersBeforeRequest
    ) {
      return;
    }

    this.store.dispatch(
      new QuickOrderActions.GetQuickOrderSuggestions({
        term: query,
        searchConfig: {
          pageSize: config.maxSuggestions,
        },
      })
    );
  }

  getSuggestionResults(): Observable<string[]> {
    return this.store.pipe(
      select(QuickOrderSelectors.getQuickOrderSuggestions),
      map((suggestions) => suggestions.map((suggestion) => suggestion.value))
    );
  }

  clearResults(): void {
    this.store.dispatch(new QuickOrderActions.ClearQuickOrderSearchResult());
  }
}
