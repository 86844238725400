import { Component, Input } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';

@Component({
  selector: 'jaga-order-summary',
  templateUrl: './jaga-order-summary.component.html',
})
export class JagaOrderSummaryComponent {
  @Input()
  cart: Cart;

  @Input()
  showAppliedCoupons = true;
}
