import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { JagaCartTotalsComponent } from './jaga-cart-totals.component';
import { RouterLink } from '@angular/router';
import { JagaOrderSummaryModule } from '../../../jaga-cart-shared/jaga-order-summary/jaga-order-summary.module';

@NgModule({
  declarations: [JagaCartTotalsComponent],
  imports: [
    CommonModule,
    I18nModule,
    JagaOrderSummaryModule,
    UrlModule,
    RouterLink,
  ],
  providers: [
    provideConfig({
      cmsComponents: {
        CartTotalsComponent: {
          component: JagaCartTotalsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class JagaCartTotalsCartModule {}
