import { Component, OnDestroy, ViewContainerRef } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { RoutingService } from '@spartacus/core';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import { OrderFacade } from '@spartacus/order/root';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { take, tap } from 'rxjs/operators';
import { MollieOrderService } from '../../../mollie/services/mollie-order.service';

@Component({
  selector: 'jaga-place-order',
  templateUrl: './jaga-place-order.component.html',
})
export class JagaPlaceOrderComponent
  extends CheckoutPlaceOrderComponent
  implements OnDestroy
{
  placeOrderLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    orderFacade: OrderFacade,
    routingService: RoutingService,
    fb: FormBuilder,
    protected mollieOrderService: MollieOrderService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected activeCartFacade: ActiveCartFacade
  ) {
    super(orderFacade, routingService, fb, launchDialogService, vcr);
  }

  submitPlaceOrderForm() {
    this.activeCartFacade
      .getActive()
      .pipe(
        take(1),
        tap((cart) => {
          if (cart?.paymentType?.code === 'CARD') {
            return this.placeMollieOrder();
          }
          return this.submitForm();
        })
      )
      .subscribe();
  }

  placeMollieOrder() {
    if (this.checkoutSubmitForm.valid) {
      this.placedOrder = this.launchDialogService.launch(
        LAUNCH_CALLER.PLACE_ORDER_SPINNER,
        this.vcr
      );

      this.mollieOrderService
        .placeOrder(this.checkoutSubmitForm.valid)
        .subscribe({
          error: () => {
            if (!this.placedOrder) {
              return;
            }

            this.placedOrder
              .subscribe((component) => {
                this.launchDialogService.clear(
                  LAUNCH_CALLER.PLACE_ORDER_SPINNER
                );
                if (component) {
                  component.destroy();
                }
              })
              .unsubscribe();
          },
          next: () => this.onSuccess(),
        });
    } else {
      this.checkoutSubmitForm.markAllAsTouched();
    }
  }
}
