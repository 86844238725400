import { NgModule } from '@angular/core';
import { JagaCheckoutB2BComponentsModule } from './components/jaga-checkout-b2b-components.module';
import { provideDefaultConfig } from '@spartacus/core';
import { jagaCheckoutB2bRoutingConfig } from './root/config/jaga-checkout-b2b-routing-config';
import { JagaCheckoutB2BCoreModule } from './core/checkout-b2b-core.module';
import { JagaCheckoutB2BOccModule } from './occ/jaga-checkout-b2b-occ.module';
import { CheckoutB2BEventModule } from '@spartacus/checkout/b2b/root';

@NgModule({
  imports: [
    CheckoutB2BEventModule,
    JagaCheckoutB2BComponentsModule,
    JagaCheckoutB2BCoreModule,
    JagaCheckoutB2BOccModule,
  ],
  providers: [provideDefaultConfig(jagaCheckoutB2bRoutingConfig)],
})
export class JagaCheckoutB2BModule {}
