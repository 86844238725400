import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { PRODUCT_VARIANTS_FEATURE, ProductVariantsRootModule } from '@spartacus/product/variants/root';

@NgModule({
  declarations: [],
  imports: [ProductVariantsRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [PRODUCT_VARIANTS_FEATURE]: {
          module: () => import('@spartacus/product/variants').then((m) => m.ProductVariantsModule),
        },
      },
    }),
  ],
})
export class ProductVariantsFeatureModule {}
