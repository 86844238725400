import { NgModule } from '@angular/core';
import { JagaWebsiteSelectorModule } from '../jaga-website/components/jaga-website-selector/jaga-website-selector.module';
import { JagaContentModule } from '../jaga-content/jaga-content.module';
import { JagaCommonModule } from '../jaga-common/jaga-common.module';
import { JagaUserModule } from '../jaga-user/jaga-user.module';
import { JagaOrderConfirmationModule } from '../jaga-order/jaga-order-confirmation/jaga-order-confirmation.module';
import { JagaProductModule } from '../jaga-product/jaga-product.module';
import { JagaMyAccountModule } from '../jaga-my-account/jaga-my-account.module';
import { JagaOccModule } from '../jaga-website/occ/jaga-occ.module';
import { JagaTranslationModule } from '../jaga-translation/jaga-translation.module';
import { JagaCartModule } from '../jaga-cart/jaga-cart.module';
import { MollieCheckoutModule } from '../jaga-checkout/mollie/mollie-checkout.module';
import { JagaQuickOrderModule } from '../jaga-order/jaga-quick-order/jaga-quick-order.module';

@NgModule({
  imports: [
    JagaUserModule,
    JagaCommonModule,
    JagaCartModule,
    JagaOccModule,
    JagaProductModule,
    JagaContentModule,
    JagaMyAccountModule,
    JagaOrderConfirmationModule,
    MollieCheckoutModule,
    JagaQuickOrderModule,
    JagaTranslationModule,
    JagaWebsiteSelectorModule,
  ],
})
export class JagaModule {}
