import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JagaQuickOrderListComponent } from './components/jaga-quick-order-list/jaga-quick-order-list.component';
import {
  CmsConfig,
  I18nModule,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import { QuickOrderStoreModule } from './store/quick-order.store.module';
import { JagaQuickOrderItemComponent } from './components/jaga-quick-order-item/jaga-quick-order-item.component';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { JagaQuickOrderSearchComponent } from './components/jaga-quick-order-search/jaga-quick-order-search.component';
import { JagaCommonModule } from '../../jaga-common/jaga-common.module';

@NgModule({
  declarations: [
    JagaQuickOrderListComponent,
    JagaQuickOrderItemComponent,
    JagaQuickOrderSearchComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    JagaCommonModule,
    QuickOrderStoreModule,
    UrlModule,
    MediaModule,
    RouterModule,
    FormsModule,
    IconModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CMSQuickOrderComponent: {
          component: JagaQuickOrderListComponent,
        },
      },
    }),
  ],
})
export class JagaQuickOrderModule {}
