import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ConverterService,
  InterceptorUtil,
  Occ,
  OCC_USER_ID_ANONYMOUS,
  OccEndpointsService,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { CheckoutAdapter } from '@spartacus/checkout/base/core';
import { CheckoutState } from '@spartacus/checkout/base/root';
import { Order, ORDER_NORMALIZER } from '@spartacus/order/root';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { take } from 'rxjs/operators';

const CHECKOUT_PARAMS =
  'deliveryAddress(FULL),deliveryMode,paymentInfo(FULL),billingAddress(FULL)';
const CARTS_ENDPOINT = '/carts/';

@Injectable()
export class JagaOccCheckoutAdapter implements CheckoutAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected activeCartFacade: ActiveCartFacade,
  ) {}

  protected getPlaceOrderEndpoint(
    userId: string,
    cartId: string,
    termsChecked: string,
  ): string {
    return this.occEndpoints.buildUrl('placeOrder', {
      urlParams: { userId },
      queryParams: { cartId, termsChecked },
    });
  }

  placeOrder(
    userId: string,
    cartId: string,
    termsChecked: boolean,
  ): Observable<Order> {
    this.activeCartFacade.getActive().pipe(take(1)).subscribe();

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http
      .post<Occ.Order>(
        this.getPlaceOrderEndpoint(userId, cartId, termsChecked.toString()),
        {},
        { headers },
      )
      .pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  getCheckoutDetails(
    userId: string,
    cartId: string,
  ): Observable<CheckoutState> {
    const url = this.getEndpoint(userId, CARTS_ENDPOINT) + cartId;
    const params = new HttpParams({
      fromString: `fields=${CHECKOUT_PARAMS}`,
    });
    return this.http.get<CheckoutState>(url, { params });
  }

  protected getEndpoint(userId: string, subEndpoint: string): string {
    const orderEndpoint = `users/${userId}${subEndpoint}`;
    return this.occEndpoints.buildUrl(orderEndpoint);
  }
}
