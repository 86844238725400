import { Injectable } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { GlobalMessageService, RoutingService } from '@spartacus/core';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { UpdatePasswordComponentService } from '@spartacus/user/profile/components';
import { JagaCustomFormValidators } from '@jaga/util';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {
  JagaResetPasswordState,
  UpdateResetPasswordErrorsAction,
} from '@jaga/user';
import { Store } from '@ngrx/store';

@Injectable()
export class JagaUpdatePasswordComponentService extends UpdatePasswordComponentService {
  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    private store: Store<JagaResetPasswordState>,
  ) {
    super(userPasswordService, routingService, globalMessageService);
    this.Init();
  }

  private subscription = new Subscription();

  form: FormGroup = new FormGroup(
    {
      oldPassword: new FormControl('', Validators.required),
      newPassword: new FormControl('', [
        Validators.required,
        JagaCustomFormValidators.passwordRequirementsValidator,
      ]),
      newPasswordConfirm: new FormControl('', Validators.required),
    },
    {
      validators: JagaCustomFormValidators.passwordsMustMatchValidator(
        'newPassword',
        'newPasswordConfirm',
      ),
    },
  );

  Init() {
    this.subscription.add(
      this.form.valueChanges
        .pipe(
          map((_) => {
            return {
              ...this.form.controls.newPassword.errors,
              ...this.form.controls.newPasswordConfirm.errors,
            };
          }),
        )
        .subscribe((errors: ValidationErrors) =>
          this.store.dispatch(new UpdateResetPasswordErrorsAction(errors)),
        ),
    );
  }
}
