import { CheckoutEvent } from '@spartacus/checkout/base/root';

/**
 * An abstract event for all the remark related events.
 */
export abstract class JagaCheckoutRemarksEvent extends CheckoutEvent {}

/**
 * Emit this event to force remark reload
 */
export class CheckoutRemarksQueryReloadEvent extends JagaCheckoutRemarksEvent {
  /**
   * Event's type
   */
  static readonly type = 'CheckoutRemarksQueryReloadEvent';
}

/**
 * Emit this event to force remark reset
 */
export class CheckoutRemarksQueryResetEvent extends JagaCheckoutRemarksEvent {
  /**
   * Event's type
   */
  static readonly type = 'CheckoutRemarksQueryResetEvent';
}

/**
 * Fired when the remark has been set
 */
export class CheckoutRemarksSetEvent extends JagaCheckoutRemarksEvent {
  /**
   * Event's type
   */
  static readonly type = 'CheckoutRemarksSetEvent';

  /**
   * Remark
   */
  remarks: string;
}
