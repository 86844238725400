<div [formGroup]="mode">
  <div class="row">
    <div class="col-12">
      <h5 class="h5">
        {{ "checkoutShipping.shippingMethod" | cxTranslate }}
      </h5>
    </div>
    <div class="col-12">
      <ng-container
        *ngIf="(supportedDeliveryModes$ | async)?.length; else loading"
      >
        <div
          *ngFor="let mode of supportedDeliveryModes$ | async"
          class="form-check"
        >
          <input
            (change)="changeMode(mode.code)"
            [value]="mode.code"
            aria-checked="true"
            class="form-check-input"
            formControlName="deliveryModeId"
            id="deliveryMode-{{ mode.code }}"
            role="radio"
            type="radio"
          />
          <label
            class="cx-delivery-label form-check-label form-radio-label"
            for="deliveryMode-{{ mode.code }}"
          >
            <div class="cx-delivery-mode">{{ mode.name }}</div>
            <div class="cx-delivery-price">
              {{ mode.deliveryCost.formattedValue }}
            </div>
            <div class="cx-delivery-details">{{ mode.description }}</div>
          </label>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="enableDatePickerCheckout | async">
    <div class="row mt-2">
      <div class="col-12">
        <h5 class="h5">
          {{ "checkoutShipping.deliveryDate.title" | cxTranslate }}
        </h5>
      </div>

      <div class="col-12 delivery-date" *ngIf="minDate$ | async as minDate">
        <input
          [formControlName]="deliveryDateFormName"
          [placeholder]="
            'checkoutShipping.deliveryDate.placeholder' | cxTranslate
          "
          [markDisabled]="isDisabled"
          (dateSelect)="saveDeliveryDate()"
          class="deliveryDate"
          ngbDatepicker
          #d="ngbDatepicker"
        />
        <button class="btn delivery-btn" (click)="d.toggle()">
          <i class="far fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="row mt-2">
    <div class="col-12">
      <h5 class="h5">
        {{ "remarks.remarks" | cxTranslate }}
      </h5>
    </div>

    <div class="col-12">
      <label>
        <textarea
          [formControlName]="remarksFormName"
          [placeholder]="'remarks.placeholder' | cxTranslate"
          class="remarks"
          maxlength="130"
        >
        </textarea>
      </label>
    </div>
  </div>

  <div class="row cx-checkout-btns">
    <div class="col-12 col-md-6">
      <button (click)="back()" class="btn btn-block btn-action">
        {{ "common.back" | cxTranslate }}
      </button>
    </div>
    <div class="col-12 col-md-6">
      <button
        (click)="next()"
        [disabled]="deliveryModeInvalid"
        class="btn btn-block btn-primary"
      >
        {{ "common.continue" | cxTranslate }}
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
