import { Component } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { OrderHistoryComponent } from '@spartacus/order/components';
import {
  OrderHistoryFacade,
  ReplenishmentOrderHistoryFacade,
} from '@spartacus/order/root';

@Component({
  selector: 'jaga-order-history',
  templateUrl: './jaga-order-history.component.html',
})
export class JagaOrderHistoryComponent extends OrderHistoryComponent {
  constructor(
    routing: RoutingService,
    orderHistoryFacade: OrderHistoryFacade,
    translation: TranslationService,
    userReplenishmentOrderService: ReplenishmentOrderHistoryFacade
  ) {
    super(
      routing,
      orderHistoryFacade,
      translation,
      userReplenishmentOrderService
    );
  }
}
