import { Component } from '@angular/core';
import {
  Address,
  CmsOrderDetailOverviewComponent,
  TranslationService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { Card } from '@spartacus/storefront/shared/components/card/card.component';
import { filter, map } from 'rxjs/operators';
import {
  OrderDetailsService,
  OrderOverviewComponent,
} from '@spartacus/order/components';

import { DeliveryMode, PaymentType } from '@spartacus/cart/base/root';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'jaga-order-detail-shipping',
  templateUrl: './jaga-order-detail-shipping.component.html',
})
export class JagaOrderDetailShippingComponent extends OrderOverviewComponent {
  constructor(
    protected orderDetailsService: OrderDetailsService,
    private translationService: TranslationService,
    component: CmsComponentData<CmsOrderDetailOverviewComponent>,
  ) {
    super(translationService, orderDetailsService, component);
  }

  getPaymentTypeCardContent(paymentType: PaymentType): Observable<Card> {
    return this.translationService.translate('paymentTypes.title').pipe(
      filter(() => Boolean(paymentType)),
      map((title) => ({
        title,
        text: [paymentType.displayName],
      })),
    );
  }

  getShippingMethodCardContent(shipping: DeliveryMode): Observable<Card> {
    return this.translationService
      .translate('checkoutShipping.shippingMethod')
      .pipe(
        map((title) => {
          return {
            title,
            text: [shipping.name],
          };
        }),
      );
  }

  getAddressCardContent(address: Address): Observable<Card> {
    return this.translationService.translate('addressCard.shipTo').pipe(
      map((textTitle) => {
        return this.getFormattedAddress(address, textTitle);
      }),
    );
  }

  getBillingAddressCardContent(billingAddress: Address): Observable<Card> {
    return this.translationService.translate('addressCard.billTo').pipe(
      map((textTitle) => {
        return this.getFormattedAddress(billingAddress, textTitle);
      }),
    );
  }

  getFormattedAddress(address: Address, title: string): Card {
    let region = '';
    if (address.region && address.region.name) {
      region = `${address.region.name}, `;
    }

    let name = '';
    if (address.firstName && address.lastName) {
      name = `${address.firstName} ${address.lastName}`;
    }

    return {
      title,
      textBold: '',
      text: [
        name,
        address.companyName,
        address.line1,
        `${address.postalCode} ${address.town}`,
        region + address.country.name,
        address.phone,
      ],
    };
  }
}
