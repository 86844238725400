import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JagaReviewSubmitModule } from './jaga-review-submit/jaga-review-submit.module';
import { JagaPaymentTypeModule } from './jaga-payment-type/jaga-payment-type.module';
import { JagaShippingAddressModule } from './jaga-shipping-address/jaga-shipping-address.module';

@NgModule({
  imports: [
    CommonModule,
    JagaPaymentTypeModule,
    JagaShippingAddressModule,
    JagaReviewSubmitModule,
  ],
  providers: [
    // {
    //   provide: CheckoutAuthGuard,
    //   useExisting: CheckoutB2BAuthGuard,
    // },
    // {
    //   provide: CheckoutStepsSetGuard,
    //   useExisting: CheckoutB2BStepsSetGuard,
    // },
  ],
})
export class JagaCheckoutB2BComponentsModule {}
