import {
  quickOrderAdapter,
  QuickOrderEntriesState,
} from '../quick-order-state';
import {
  ADD_QUICK_ORDER_ENTRY_FAIL,
  ADD_QUICK_ORDER_ENTRY_SUCCESS,
  CHANGE_QUICK_ORDER_QUANTITY_ENTRY,
  CLEAR_QUICK_ORDER_ENTRIES,
  REMOVE_QUICK_ORDER_ENTRY,
} from '../actions/quick-order-entries.action';
import { QuickOrderActions } from '../actions';

export const initialState: QuickOrderEntriesState =
  quickOrderAdapter.getInitialState();

export function reducer(
  state: QuickOrderEntriesState = initialState,
  action: QuickOrderActions.QuickOrderEntriesActions
) {
  switch (action.type) {
    case CLEAR_QUICK_ORDER_ENTRIES:
      return quickOrderAdapter.removeAll(state);
    case REMOVE_QUICK_ORDER_ENTRY:
      return quickOrderAdapter.removeOne(action.id, state);
    case CHANGE_QUICK_ORDER_QUANTITY_ENTRY:
      return quickOrderAdapter.updateOne(
        {
          id: action.id,
          changes: action.changes,
        },
        state
      );
    case ADD_QUICK_ORDER_ENTRY_SUCCESS:
      return quickOrderAdapter.addOne(action.payload, state);
    case ADD_QUICK_ORDER_ENTRY_FAIL:
    default:
      return state;
  }
}
