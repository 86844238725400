<div class="quick-order-item-list-row">
  <div class="quick-order-list-items">
    <div class="row mx-md-0">
      <div class="col-md-3 d-none d-md-flex align-items-center">
        {{ entry.product.code }}
      </div>

      <!-- Image & Description -->
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-2 quick-order-image-container">
            <a
              [routerLink]="
                { cxRoute: 'product', params: entry.product } | cxUrl
              "
              tabindex="-1"
            >
              <cx-media [container]="entry.product.images?.PRIMARY"></cx-media>
            </a>
          </div>

          <div class="col-md-10 product-desc">
            <div
              *ngIf="entry.product.groupDescription"
              class="quick-order-name"
            >
              <a
                [routerLink]="
                  { cxRoute: 'product', params: entry.product } | cxUrl
                "
                class="quick-order-link"
              >
                {{ entry.product.groupDescription }}
              </a>
            </div>
            <div *ngIf="entry.product.name" class="name">
              <a
                [routerLink]="
                  { cxRoute: 'product', params: entry.product } | cxUrl
                "
                class="cx-link"
              >
                {{ entry.product.name }}
              </a>
            </div>
            <div *ngIf="entry.product.code && !isDemo" class="quick-order-code">
              {{ "cartItems.id" | cxTranslate }} {{ entry.product.code }}
            </div>
            <!-- Variants -->
            <ng-container *ngIf="entry.product.baseOptions?.length">
              <div
                *ngFor="
                  let variant of entry.product.baseOptions[0]?.selected
                    ?.variantOptionQualifiers
                "
                class="quick-order-property"
              >
                <div
                  *ngIf="variant.name && variant.value"
                  class="quick-order-label"
                >
                  {{ variant.name }}: {{ variant.value }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="col-md-2 d-flex align-items-center">
        <!-- Item Price -->
        <div *ngIf="entry.basePrice" class="quick-order-price">
          <div *ngIf="entry.basePrice" class="py-2 quick-order-value">
            {{ entry.basePrice?.formattedValue }}
          </div>
        </div>
      </div>

      <div class="col-md-3 d-flex align-items-center">
        <!-- Item Quantity -->
        <div class="entry-counter quick-order-qty">
          <button
            (click)="decrement(entry)"
            [disabled]="isDemo"
            tabindex="-1"
            type="button"
          >
            -
          </button>

          <input
            (blur)="changeQuantity(entry, $event)"
            [disabled]="isDemo"
            [max]="max"
            [min]="min"
            [step]="step"
            [value]="entry.quantity"
            type="number"
          />

          <button
            (click)="increment(entry)"
            tabindex="-1"
            [disabled]="isDemo"
            type="button"
          >
            +
          </button>
        </div>
        <div class="ml-auto">
          <div
            (click)="removeItem(entry)"
            class="quick-order-remove fas fa-trash-alt"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
