import { NgModule } from '@angular/core';
import {
  CART_SAVED_CART_FEATURE,
  SavedCartRootModule,
} from '@spartacus/cart/saved-cart/root';
import { CmsConfig, provideConfig } from '@spartacus/core';

@NgModule({
  declarations: [],
  imports: [SavedCartRootModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [CART_SAVED_CART_FEATURE]: {
          module: () =>
            import('@spartacus/cart/saved-cart').then((m) => m.SavedCartModule),
        },
      },
    }),
  ],
})
export class SavedCartFeatureModule {}
